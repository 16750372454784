import React from 'react'
import {  NavLink } from 'react-router-dom';


const BusinessFundingSolution = () => {
    return (
        <div className=''>
           <div className=' '>
           <div class=" text-center  bg-cover w-full  bg-center  bg-no-repeat" style={{ backgroundImage: `url(${'../img/factors_of_demand_forecasting-01crop-scaled.jpg'})` }}>
                <h1 class=" py-40 md:py-72 text-3xl lg:text-6xl backdrop-contrast-50   opacity-100 text-white font-bold">Business Funding Solution</h1>
            </div>
           </div>
            <div>
                <h1 class="lg2:px-32 lg3:px-72  px-10  text-4xl lg:text-6xl text-blue-900 font-bold">Unsecured
                    Business Loans</h1>
                <p class="lg2:px-32 lg3:px-72 px-10   text-lg py-5">Unsecured business loans offer greater
                    flexibility for small and growing
                    businesses that want to access finance quickly without offering an asset, such as property or collateral, as
                    security for the loan.</p>
                <ul class="lg2:px-36 lg3:px-72  px-16 list-disc leading-8 py-5">
                    <li >Check your eligibility in minutes</li>
                    <li>Compare a wide range of lenders</li>
                    <li>Find out how much you could borrow</li>
                </ul>
                <div class="text-center mt-4">
                <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">APPLY
                        NOW</button>
                    </NavLink>
                </div>
            </div>
            <div class=" lg2:px-32 lg3:px-72 px-10 py-5">
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">What are unsecured business loans?</h4>
                    <p class="text-lg text-gray-700 leading-8 ">An unsecured business loan is a type of finance that does not
                        require the borrower to provide security. Because the loan is ‘unsecured’, businesses aren’t at risk of
                        losing valuable business equipment, property or land when they choose this funding route.</p>
                    <p class="text-lg text-gray-700 leading-8 "> Unsecured business loans offer a straightforward funding
                        solution for UK businesses. You could borrow up to £500,000 without having to secure the loan against
                        important company assets.</p>
                    <p class="text-lg text-gray-700 leading-8 ">A business loan without security provides a flexible source of
                        funding for businesses that lack assets or do not want to secure them against a loan. You can access
                        finance quickly – often within a couple of hours – and simply pay it back within an agreed timeframe.
                    </p>
                    <p class="text-lg text-gray-700 leading-8 "> We have various unsecured business loan options to help
                        businesses of all sizes, from start up businesses to limited companies. We offer the following unsecured
                        business finance options:</p>
                    <p class="text-lg text-gray-700 leading-8 ">
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Startup business loans</a> –
                        This is great
                        if you are about to start your new business
                        venture. <br />
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Sole trader loans </a>– Great if
                        you are
                        set up as a sole trader, we have various lenders
                        who can help sole traders. <br />
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Self employed loans</a> – We
                        have a
                        variety of lenders who can provide loans for a
                        self-employed person. <br />
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold"> Limited company loans</a> – If
                        you are
                        registered with companies house, we can help your
                        limited company get the finance it needs to grow. <br />
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold"> SME business loans </a> – If
                        your small
                        or medium-sized business is trading, we can help
                        you with your business finance requirements. <br />
                    </p>
                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">How do unsecured business loans work?</h4>

                    <p class="text-lg text-gray-700 leading-8 py-2">Unsecured business loans work by providing your business
                        with upfront capital without requiring security. Typically you repay the loan in monthly or quarterly
                        instalments over a fixed period, known as the loan ‘term’. There is a wide selection of unsecured
                        business loans for UK firms that offer flexible terms.</p>
                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">How are unsecured business loans paid back?
                    </h4>
                    <p class="text-lg text-gray-700 leading-8 py-2">An unsecured business loan has a straightforward repayment
                        structure. You borrow money from a lender after agreeing to make fixed monthly payments at a pre-agreed
                        interest rate. Payments are made over the loan term, until the borrowed amount has been paid back in
                        full. With unsecured loans, interest rates can be higher.</p>

                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">What is the difference between secured and unsecured
                        business loans?
                    </h4>
                    <p class="text-lg text-gray-700 leading-8 py-2">As mentioned above, unsecured business loans aren’t backed
                        by any assets. Because of this, they pose a greater risk for the lender, and higher interest rates are
                        usually charged. In contrast, secured business loans are financial products that require businesses to
                        offer up collateral as part of the borrowing agreement.</p>

                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">What are the advantages of unsecured business loans?</h4>
                    <p class="text-lg text-gray-700 leading-8 py-2">There are so many benefits to choosing unsecured business
                        finance. If you need a quick injection of cash for your business without offering the security of an
                        asset, an unsecured business loan could offer the fast, flexible and easy funding solution you’ve been
                        looking for. The pros of unsecured business loans include:
                    </p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Borrow £1,000 up to £500,000 +
                    </p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Does not require any asset security</p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Flexible repayment terms from 1 month to 5 years +</p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Minimal paperwork required</p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Funds can be released quickly after approval</p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Short to medium term lending solution</p>
                    <p class="text-lg text-gray-700 leading-8 py-2">Keep your company assets safe</p>
                    <p class="text-lg text-gray-700 leading-8">Unsecured business loans offer great advantages for businesses
                        operating in the UK.</p>
                    <p class="text-lg text-gray-700 leading-8">Both well-established businesses and relatively new ventures can
                        enjoy a number of significant benefits.</p>
                    <p class="text-lg text-gray-700 leading-8 py-2">
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">No assets required</a><br />
                        With an unsecured business loan, funds can be made available faster than other types of business loans.
                        As assets are not required as security, they offer a highly accessible source of funding for business
                        owners.
                    </p>
                    <p class="text-lg text-gray-700 leading-8 py-2">
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Use for any purpose</a><br />
                        Whether you’re looking to invest in new equipment, expand your product range, fund training
                        opportunities for your staff or break into new markets in the UK or overseas, there are no limits to how
                        you use this type of business loan.
                    </p>
                    <p class="text-lg text-gray-700 leading-8 py-2">
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Faster approval and
                            funding</a><br />
                        As you do not require an asset or collateral as security for an unsecured business loan, your instant
                        business loan could be agreed within a matter of hours. Depending on your circumstances, you could
                        qualify for an unsecured loan of up to £500,000 or more.
                    </p>
                    <p class="text-lg text-gray-700 leading-8 py-2">
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Flexible repayment terms</a><br />
                        Our alternative business funding providers can offer you a highly flexible finance arrangement; many
                        offer fixed repayment schedules with no hidden fees and interest rates are agreed upfront. Loans can be
                        repaid over a short or medium term, typically between 1 month to 5 years.
                    </p>
                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">Does my business qualify for an unsecured business loan?
                    </h4>
                    <p class="text-lg text-gray-700 leading-8  py-2"> To qualify for an unsecured business loan in the UK, you
                        must
                        have been trading for at least 4 months and have a minimum annual turnover of £10,000. There are
                        unsecured loan options for sole traders too.</p>

                    <p class="text-lg text-gray-700 leading-8  py-2"> It is worth noting that although you will not need to
                        secure
                        your loan against a personal or company asset, you may be required to have a good personal and business
                        credit history.</p>
                    <p class="text-lg text-gray-700 leading-8 py-2 "> Operating for at least 4 months</p>
                    <p class="text-lg text-gray-700 leading-8  py-2">Have a UK bank account</p>
                    <p class="text-lg text-gray-700 leading-8  py-2">Limited company, limited liability partnership (LLP) or
                        sole
                        traders registered in the UK</p>
                    <p class="text-lg text-gray-700 leading-8  py-2"> Annual turnover of at least £10,000</p>
                    <p class="text-lg text-gray-700 leading-8  py-2"> A personal guarantee may also be required, but this could
                        enable you to secure higher levels of funding. If your situation changes and your business is unable to
                        repay the loan, the personal guarantor may be required to pay back the remaining balance under the
                        agreed terms.</p>
                    <p class="text-lg text-gray-700 leading-8  py-2"> If you do not meet these criteria, we can still help you
                        access
                        the finance you need. We offer a wide range of other business finance options and can help you find the
                        right funding solution for your business.</p>
                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">Will I need to provide a form of security or personal
                        guarantee?
                    </h4>
                    <p class="text-lg text-gray-700 leading-8  py-2"> Possibly. A personal guarantee is a legal agreement made
                        by the business owner or director who becomes personally liable for paying any debt should the business
                        be in the unfortunate position of not being able to repay the loan.</p>

                    <p class="text-lg text-gray-700 leading-8  py-2"> The owner or director becomes the ‘guarantor’. It can be
                        made with or without supported security such as a legal charge on the guarantor’s personal property.</p>

                    <p class="text-lg text-gray-700 leading-8  py-2"> A PG can differ between lenders. Some will insist on a PG
                        with supporting security while others may not – it’s good to discuss the terms of any arrangement if
                        they are required to secure the business finance.
                    </p>
                    <p class="text-lg text-gray-700 leading-8 ">
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Limited companies and LLP’s </a>
                        –
                        If your business is a limited liability partnership (LLP), many lenders will expect you to provide a PG.
                        Any stakeholders and directors with a minimum of 20% – 25% stake in the limited business may be expected
                        to provide a PG.<br />
                        <a href="" class="text-blue-900 underline underline-offset-4 font-bold">Sole traders and partnerships
                        </a>–Business owners who are classed as sole traders or partnerships with unlimited liability will
                        invariably be personally liable. The funding will be based on the personal credit history of the
                        business owner.<br />
                    </p>
                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold">What are the documents required for an unsecured business
                        loan?
                    </h4>
                    <p class="text-lg text-gray-700 leading-8  py-2">When applying for unsecured business loans in the UK, there
                        are certain documents that you need to provide. These documents vary from lender to lender, but as a
                        general rule of thumb you should be ready to present:</p>
                    <ul class="px-10 lg2:px-32 lg3:px-72 list-disc leading-8 py-5">
                        <li>Proof of identity and address</li>
                        <li>Business verification documents</li>
                        <li>Supporting documents (business bank statements)</li>
                        <li>Personal guarantee (if required for your unsecured loan agreement)</li>

                    </ul>
                </div>
                <div>
                    <h4 class="py-4 text-gray-700 text-xl font-bold"> Can I get an unsecured business loan for my startup
                        business?
                    </h4>
                    <p class="text-lg text-gray-700 leading-8 ">Yes, you can get unsecured business loans for startups.
                        This means that even if you haven’t started trading yet or have only been trading up to 24 months there
                        is a way to raise the start-up funds you need to get going without securing the finance on any of your
                        personal assets such as your home.</p>
                    <p class="text-lg text-gray-700 leading-8">It’s unlikely that as a startup you’ll have assets readily
                        available to put up as security for business borrowing. At Capalona we understand the importance of not
                        risking company assets that are fundamental for business growth, particularly in the early stages of set
                        up! <b>Lots of the lenders we work with offer start up unsecured business loans</b>, but it’s important
                        to know
                        that you might face higher interest rates.</p>
                    <p class="text-lg text-gray-700 leading-8">Unsecured startup business loans carry greater risk for the
                        lender because startups tend to be inherently volatile, without years of successful trading history
                        behind them as evidence for successful borrowing. However, if your UK startup needs an unsecured
                        business loan, and you are willing to pay slightly higher interest rates, then we can help you arrange
                        unsecured funding.</p>
                </div>
            </div>
        </div>
    )
}

export default BusinessFundingSolution
