import React from "react";
import { NavLink } from "react-router-dom";

const BusinessLoans = () => {
  return (
    <>
      <div
        class=" text-center  bg-cover w-full bg-center   bg-no-repeat"
        style={{
          backgroundImage: `url(${"../img/benefits_of_business_loans.jpg"})`,
        }}
      >
        <h1 class=" py-20 md:py-72 text-3xl lg:text-6xl backdrop-contrast-50 text-white font-bold">
          Business Loans
        </h1>
      </div>

      <div class="lg:grid grid-cols-2 lg2:px-32 lg3:px-72 py-2">
        <div class="col-span-1 px-5">
          <h1 class="text-4xl text-blue-900 font-bold">
            Quick and adaptable financing for your business.
          </h1>
          <div class="px-5 lg:px-10 py-5 lg:py-10">
            <div class="border-l-4 py-6 lg:py-10 ">
              <p class="text-2xl text-center text-gray-600 italic lg:px-5 leading-8">
                Extend your business horizons with expedient and pliable loan
                choices. You can secure funds ranging from £1,000 to £500,000,
                featuring repayment terms of up to 60 months, and there are no
                penalties for early repayment.
              </p>
            </div>
          </div>
          <div>
            <img src="../img/loan.png" alt="" />
          </div>
        </div>

        <div class="col-span-1 px-16  ">
          <ul class="leading-8 list-disc text-lg py-3 lg:text-2xl text-gray-600">
            <li>Operational capital loans</li>
            <li>Business launch financing</li>
            <li>Value Added Tax (VAT) loans</li>
            <li>Small-scale enterprise financing</li>
            <li>Unprotected business loans</li>
            <li>Collateral-backed business loans</li>
            <li>Guaranteed business funding</li>
            <li>Vendor cash advance</li>
            <li>Resource-based financing</li>
            <li>Bill factoring</li>
            <li>Creative innovation subsidies</li>
            <li>Assorted corporate subsidies</li>
            <li>Research and Development (R&D) tax rebates</li>
            <li>Financial recovery loan plan</li>
            <li>Energy Bill Alleviation Initiative</li>
            <li>Business inception funding</li>
            <li>Corporate property mortgages</li>
            <li>Growth financing</li>
          </ul>
          <div class="text-center mt-10 lg:pl-20">
            <NavLink to="/Contactform">
              <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
                GET A FREE QUOTE
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      <div class="bg-yellow-50 my-5">
        <h1 class="text-center text-3xl lg:text-4xl text-blue-900 font-bold">
          Collateral-Backed Business Funding
        </h1>
        <p class="px-5 lg2:px-32 lg3:px-72 text-lg py-5">
          A secured business loan allows you to acquire funding by pledging a
          business asset like real estate, machinery, or equipment as collateral
          for the required amount.
        </p>
        <ul class="px-16 lg2:px-32 lg3:px-72 list-disc leading-8">
          <li>Determine your qualification status in minutes</li>
          <li>Assess various lenders for comparison</li>
          <li>Discover your potential borrowing capacity</li>
        </ul>
        <div class="text-center py-5">
          <NavLink
            target="_blank"
            to="https://amsltdgroup.swoopfunding.com/login"
          >
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              APPLY NOW
            </button>
          </NavLink>
        </div>
        <p class="px-5 lg2:px-32 lg3:px-72 text-lg leading-8">
          If you're a business owner in the UK seeking capital to expand,
          considering an asset-backed business loan could be an excellent method
          to secure the necessary funding. Discover comprehensive insights on
          secured business loans in our SME guide below.
        </p>

        <div class="px-5 lg2:px-32 lg3:px-72 py-5">
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What is a secured business loan?
            </h4>
            <p class="text-lg text-gray-700 leading-8 ">
              A secured business loan lets businesses access funds by offering
              an asset as collateral for the loan. This type of lending is also
              referred to as asset-backed lending.
            </p>
            <p class="text-lg text-gray-700 leading-8 ">
              You can use a business asset, like property, equipment, machinery,
              or land, to secure the loan, though some lenders might accept
              other high-value assets
            </p>
            <p class="text-lg text-gray-700 leading-8 ">
              When you use an asset as a guarantee, it usually leads to better
              loan terms compared to unsecured loans. Opting for a secured loan
              also means you can access larger loan amounts, typically ranging
              from £5,000 to over £2 million, and benefit from more competitive
              interest rates.
            </p>
            <p class="text-lg text-gray-700 leading-8 ">
              Secured loans can be a viable funding option even if you have a
              poor credit history. Lenders are more inclined to consider your
              application when you offer an asset as security.
            </p>
            <p class="text-lg text-gray-700 leading-8 ">
              However, it's important to note that secured business loans carry
              some level of risk for the borrower, so it's crucial to weigh the
              potential advantages and disadvantages before applying for one.
            </p>
          </div>

          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What are the advantages of secured business loans?
            </h4>
            <p class="text-lg text-gray-700 leading-8 ">
              Repaying a secured business loan is often more cost-effective
              compared to an unsecured business loan, given that the repayments
              and interest rates tend to be lower. Lenders provide increased
              flexibility in their funding arrangements, offering generous
              repayment terms that can extend up to a decade.
            </p>

            <p class="text-lg text-gray-700 leading-8 py-2">
              {" "}
              Access to larger loan amounts, ranging from £5,000 to over £2
              million.
            </p>

            <p class="text-lg text-gray-700 leading-8 py-2">
              {" "}
              The potential to secure up to 100% of the asset's value.
            </p>

            <p class="text-lg text-gray-700 leading-8 py-2">
              {" "}
              Longer repayment periods of up to 10 years.
            </p>

            <p class="text-lg text-gray-700 leading-8 py-2">
              {" "}
              Lower interest rates resulting in smaller monthly repayments.
            </p>

            <p class="text-lg text-gray-700 leading-8 py-2">
              {" "}
              Suitability for businesses with poor or unfavorable credit
              histories.
            </p>

            <p class="text-lg text-gray-700 leading-8 py-2">
              {" "}
              A medium to long-term funding solution.
            </p>
            <p class="text-lg text-gray-700 leading-8 ">
              Our funding providers have the potential to approve your secured
              business loan application within 48 hours! Apply online now to
              explore the fast and free funding options available to you.
              <a href="" class="hover:text-yellow-400 ">
                Apply online
              </a>{" "}
              now to see the funding options you’re eligible for – it’s fast and
              free!
            </p>
          </div>

          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              How does a secured business loan function?
            </h4>
            <p class="text-lg text-gray-700 leading-8">
              Typically, a secured business loan involves the use of a
              high-value asset as collateral, such as a commercial property or
              residence. Other assets, like equipment, machinery, vehicles, and
              inventory, may also serve as collateral. The application process
              shares similarities with applying for a mortgage and may include a
              valuation step.
            </p>
            <p class="text-lg text-gray-700 leading-8">
              The majority of secured business loans come with fixed interest
              rates, and borrowers make monthly payments over an extended period
              until the loan is fully repaid. It's essential to note that if you
              fail to meet your repayment obligations, there is a risk that the
              lender may repossess the collateral asset.
            </p>
            <p class="text-lg text-gray-700 leading-8">
              1)You opt to utilize your commercial property as collateral.
            </p>
            <p class="text-lg text-gray-700 leading-8">
              2)You decide to borrow £150,000 for a 5-year term.
            </p>
            <p class="text-lg text-gray-700 leading-8">
              4)You'll make 60 monthly payments of approximately £3,187.06 each.
            </p>
            <p class="text-lg text-gray-700 leading-8">
              5)The total cost of the loan will amount to £191,223.60.
            </p>
          </div>

          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What forms of collateral can be used to secure the loan?
            </h4>
            <p class="text-lg text-gray-700 leading-8 ">
              Most lenders typically accept a wide range of tangible and
              intangible business assets as collateral for securing the loan.
              Some common examples of these assets include:
            </p>
            <ul class="list-disc  text-gray-700 leading-8 px-10 lg2:px-32 lg3:px-72 py-5">
              <li>Real estate</li>
              <li>Machinery and equipment</li>
              <li>Vehicles</li>
              <li>Personal or commercial assets</li>
            </ul>
            <p class="text-lg text-gray-700 leading-8 ">
              In certain situations, lenders may consider a combination of
              assets to secure the loan. Additionally, you might be able to use
              personal assets like a car, residential property, or shares as
              collateral for your secured business loan. Sometimes, lenders may
              also require a debenture or a personal guarantee as an additional
              form of security.
            </p>
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What distinguishes tangible from intangible assets?
            </h4>
            <p class="text-lg text-gray-700 leading-8 ">
              Tangible assets are physical, meaning you can touch and see them.
              In contrast, intangible assets are non-physical, meaning you can't
              physically interact with them. Specialized lenders may accept
              intangible assets as collateral, but determining their value can
              be more challenging compared to traditional physical assets like
              real estate or equipment.
            </p>
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What are the advantages of secured business loans?{" "}
            </h4>
            <p class=" text-lg text-gray-700 leading-8 ">
              Secured business loans come with various advantages when compared
              to unsecured loans, most notably more attractive interest rates.
              Nonetheless, similar to any financial product, they are
              accompanied by their specific set of risks.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Lower Interest Rates: Secured loans typically have lower interest
              rates compared to unsecured loans because they are less risky for
              lenders. Using an asset as collateral provides them with security.
            </p>
            <p class="text-lg text-gray-700 leading-8  ">
              Higher Loan Amounts: Since these loans are secured against
              valuable assets, you can usually borrow larger sums of money. The
              amount you can borrow is often linked to the value of the asset
              you provide as collateral.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Extended Repayment Terms: Secured loans often come with longer
              repayment periods, which can make your monthly payments more
              manageable.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Easier Approval: Even if you have a less-than-perfect credit
              history, you're more likely to be approved for a secured loan
              because the collateral reduces the lender's risk.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Flexible Use of Funds: You can typically use the funds from a
              secured loan for a wide range of business purposes, from expansion
              and investment to debt consolidation. – Is it possible to use a
              property with an existing mortgage as collateral for the loan?
              Certainly, you can. It's important to mention that if you're using
              commercial property or land as an asset, and it already has an
              existing mortgage, the lender may register either a legal charge
              or an equitable charge. Here's a breakdown of the distinctions
              between the two.
            </p>
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              Is it possible to use a property with an existing mortgage as
              collateral for the loan?
            </h4>
            <p class=" text-lg text-gray-700 leading-8 ">
              Certainly, you can. It's important to mention that if you're using
              commercial property or land as an asset, and it already has an
              existing mortgage, the lender may register either a legal charge
              or an equitable charge. Here's a breakdown of the distinctions
              between the two
            </p>
            {/* <p class=" text-lg text-gray-700 leading-8 ">
              If you are a small business or sole trader and you are struggling
              with debt, you can get free business debt advice and support
              through charities like the Business Debtline.
            </p>
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              Can I secure the loan against a property with an existing
              mortgage?
            </h4>
            <p class=" text-lg text-gray-700 leading-8 ">
              Yes, you can. It is worth noting that if you are offering
              commercial property or land as an asset, which has an existing
              mortgage, the lender may register a legal or equitable charge.
              Here are the breakdown and the differences between the two.
            </p> */}
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What is a legal charge?
            </h4>

            <p class=" text-lg text-gray-700 leading-8 ">
              A legal charge provides the lender with the authority to sell the
              asset if you fail to make the agreed repayments. However, if
              consent is required from an existing lender, such as a mortgage
              provider, registering a legal charge can be a time-consuming
              process, and the funds may not become available for several weeks.
            </p>
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What is an equitable charge?
            </h4>
            <p class=" text-lg text-gray-700 leading-8 ">
              An equitable charge is an alternative method used by many lenders
              offering secured business loans. Unlike a legal charge, it does
              not grant the lender the power to sell the property if you default
              on your repayments. This means that the process of obtaining the
              funds is typically faster, and there is no requirement for consent
              from your existing mortgage provider.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              With an equitable charge, the funds can often be made available
              within hours of approval, providing a more streamlined and
              expedited lending process.
            </p>
          </div>
          <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              What if I lack business assets to use as collateral?
            </h4>
            <p class=" text-lg text-gray-700 leading-8 ">
              If you don't have the necessary assets to secure a business loan
              or prefer not to use your property as collateral, an unsecured
              business loan might be a more suitable option. Alternatively, we
              offer a variety of other business financing solutions that could
              be the right fit for your needs.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Calculator for Secured Business Loans
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              You can utilize our online tool as a fast reference guide to
              approximate the interest costs and monthly payments for a secured
              business loan. Just enter the loan amount, APR, and the term's
              duration to get an estimate of your borrowing capacity and the
              overall finance cost.
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Business Loans Without Collateral
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              Unsecured business loans provide enhanced flexibility for small
              and expanding businesses seeking rapid access to funding, all
              without the need to pledge an asset, like real estate or
              collateral, as security for the loan
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              -Assess your qualification status within minutes{" "}
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              -Compare across a diverse array of lenders
            </p>
            <p class=" text-lg text-gray-700 leading-8 ">
              -Determine the potential loan amount available to you
            </p>
          </div>
          {/* <div>
            <h4 class="py-4 text-gray-700 text-xl font-bold">
              Secured business loan calculator
            </h4>
            <p class=" text-lg text-gray-700 leading-8 ">
              Use our online calculator as a quick guidance tool to help
              estimate the cost of interest and monthly repayments of a secured
              business loan. Simply input a loan amount, APR and term length to
              get an idea on how much you can afford to borrow and the total
              cost of finance.
            </p>
          </div> */}
          <div class="text-center mt-4">
            <NavLink to="/Contactform">
              <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
                GET A FREE QUOTE
              </button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="px-5 lg2:px-32 lg3:px-72 ">
        <h1 class="text-center text-3xl px-5 lg:text-4xl text-blue-900 font-bold">
          Unsecured Business Loans
        </h1>
        <p class=" text-lg py-5">
          Unsecured business loans provide enhanced flexibility for small and
          expanding businesses seeking rapid access to funding, all without the
          need to pledge an asset, like real estate or collateral, as security
          for the loan.
        </p>
        <ul class="px-4 list-disc leading-8 py-5">
          <li>Assess your qualification status within minutes</li>
          <li>Compare across a diverse array of lenders</li>
          <li>Determine the potential loan amount available to you</li>
        </ul>
        <div class="text-center mt-4">
          <NavLink to="/Contactform">
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              GET A FREE QUOTE
            </button>
          </NavLink>
        </div>
      </div>
      <div class="px-5 lg2:px-32 lg3:px-72 py-5">
        <div>
          <h4 class="py-4 text-gray-700 text-xl font-bold">
            What exactly is an unsecured business loan?
          </h4>
          <p class="text-lg text-gray-700 leading-8 ">
            An unsecured business loan is a{" "}
            <span class="font-bold">
              form of financing that doesn't necessitate the borrower to offer
              collateral.
            </span>
            . Since it's termed 'unsecured,' businesses don't face the danger of
            forfeiting valuable business assets, such as equipment, real estate,
            or land when they opt for this funding method.
          </p>
          <p class="text-lg text-gray-700 leading-8 ">
            Unsecured business loans present a simple funding solution for
            businesses in the UK. You have the potential to obtain up to
            £500,000 without the need to collateralize the loan with significant
            company assets.
          </p>
          <p class="text-lg text-gray-700 leading-8 ">
            This type of business loan, which doesn't require security, offers a
            versatile funding source for businesses that either lack assets or
            prefer not to pledge them against the loan. Quick access to
            financing is often possible, typically within a couple of hours, and
            you can easily repay the loan within an agreed-upon period.
          </p>
          <p class="text-lg text-gray-700 leading-8 ">
            {" "}
            We provide a range of unsecured business loan choices to assist
            businesses of all sizes, spanning from new startups to established
            limited companies. We offer the following unsecured business finance
            alternatives:
          </p>
          <ul class="list-disc  text-gray-700 leading-8 px-4  py-5">
            <li>
              <a href="">Startup business loans</a> – Ideal for those embarking
              on a new business endeavor. Sole trader loans – Suitable for
              individuals operating as sole traders; we collaborate with
              numerous lenders catering to sole traders.
            </li>
            <li>
              <a href="">Self-employed loans </a>– Our pool of lenders offers a
              variety of loan options for self-employed individuals.
            </li>

            <li>
              <a href=""> Limited company loans</a> – If your company is
              registered with Companies House, we can facilitate financing to
              support the growth of your limited company.
            </li>
            <li>
              <a href=""> SME business loans </a> – We can aid your small or
              medium-sized enterprise with its business finance needs if it is
              currently operational.
            </li>
          </ul>
        </div>
        <div>
          <h4 class="py-4 text-gray-700 text-xl font-bold">
            What are the benefits of unsecured business loans?
          </h4>
          <p class="text-lg text-gray-700 leading-8 py-2">
            There are numerous advantages to opting for unsecured business
            finance. If you require a swift infusion of capital for your
            business without providing asset security, an unsecured business
            loan could provide the prompt, adaptable, and straightforward
            funding solution you've been seeking. The benefits of unsecured
            business loans comprise:
          </p>

          <ul class="px-4  list-disc leading-8 py-5">
            <li>Borrow from £1,000 to £500,000 or more</li>
            <li>No need for asset security</li>
            <li>
              Adaptable repayment periods ranging from 1 month to 5 years or
              more
            </li>
            <li>Minimal documentation needed</li>
            <li>Swift disbursement of funds post-approval</li>
            <li>A short to medium-term financing solution</li>
            <li>Safeguard your business assets</li>
          </ul>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Unsecured business loans provide substantial benefits for
            enterprises in the UK. This applies to both long-standing firms and
            relatively recent startups, granting them numerous advantages.
          </p>
        </div>
        <div>
          <h4 class="py-4 text-gray-700 text-xl font-bold">
            Is my business eligible for an unsecured business loan?
          </h4>
          <p class="text-lg text-gray-700 leading-8 py-2">
            To be eligible for an unsecured business loan in the UK, your
            business should have a trading history of at least 4 months and an
            annual turnover of at least £10,000. Unsecured loan options are also
            available for sole traders.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            It's important to keep in mind that while you won't have to provide
            security against your loan with a personal or company asset, a good
            personal and business credit history may be necessary.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Your business should meet the following criteria:
          </p>
          <ul class="px-4 list-disc leading-8 py-5">
            <li>1. Operational for a minimum of 4 months.</li>
            <li>2. Possess a UK bank account.</li>
            <li>
              3. Be registered in the UK as a limited company, limited liability
              partnership (LLP), or a sole trader.
            </li>
            <li>4. Maintain an annual turnover of at least £10,000.</li>
          </ul>
          <p class="text-lg text-gray-700 leading-8 py-2">
            A personal guarantee might also be necessary, but this can allow you
            to obtain increased funding. If your circumstances change, and your
            business cannot meet the loan's repayment, the personal guarantor
            may be obligated to settle the outstanding balance according to the
            agreed conditions.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            If you do not meet these criteria, we can still help you access the
            finance you need. We offer a wide range of other business finance
            options and can help you find the right funding solution for your
            business.
          </p>
        </div>
        <div>
          <h4 class="py-4 text-gray-700 text-xl font-bold">
            Can I compare unsecured business loan interest rates?
          </h4>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Comparing loan rates is a fundamental part of securing the best
            arrangement for your business. However, it's not solely about the
            most favorable figures. Secured and unsecured business loans have
            variances, each offering distinct pros and cons to evaluate.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            At times, the advantages of a specific business loan product can
            surpass those of other products when considering repayment terms.
            For instance, a conventional business bank loan may come with high
            costs and a relatively sluggish administrative procedure. An
            unsecured business loan from an alternative lender, on the other
            hand, might offer quicker funding, reduced paperwork, and creative
            repayment alternatives. This underscores the significance of
            comparing unsecured business loans.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            At AMS, we understand the considerable amount of time it may require
            to assess various business loan products, lenders, and interest
            rates. We aim to simplify this procedure, taking on the challenging
            task and identifying the optimal loan product for your business.
            We've also developed a useful calculator to assist you in
            determining the anticipated repayment amounts. Please explore our
            business loan calculator for additional details.
          </p>
        </div>
        <div>
          <h4 class="py-4 text-gray-700 text-xl font-bold">
            Can I obtain an unsecured business loan with a poor credit history?
          </h4>
          <p class="text-lg text-gray-700 leading-8 py-2">
            At times, it can be more challenging to secure a business loan if
            you have a poor credit history. The lenders within our network will
            conduct either a soft or hard credit check as part of your
            application to assess fraud and affordability. We frequently receive
            inquiries about business loans without credit checks. Credit checks
            are a fundamental aspect of the lending process, and we do not
            collaborate with any lenders who process applications without
            conducting credit checks. This is in your best interest as well as
            the lender's.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            This is typically a significant obstacle for business owners with an
            unfavorable credit history who have attempted and been unsuccessful
            in obtaining funding from their local traditional bank.{" "}
            <span class="font-bold">
              That's why accessing alternative business financing through our
              network of lenders can serve as a crucial solution for many
              business owners.
            </span>
            .
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Having an adverse credit history is an inevitable aspect of
            conducting business. It can occur due to various factors, such as
            your customers' delayed payments or your business encountering
            difficulties in meeting its supplier obligations – these challenges
            are nearly unavoidable throughout a company's existence.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Having a glitch on your credit history can, in some cases, be
            reasonably straightforward to remedy and increase your overall
            credit score. It’s worth looking at this before your application, so
            you have the very best chance of eligibility.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Nonetheless, don't allow it to deter you from seeking an online
            business loan. We've assisted numerous businesses in obtaining an
            unsecured business loan despite having a unfavorable or subpar
            credit history.
          </p>
        </div>
        <div>
          <h4 class="py-4 text-gray-700 text-xl font-bold">
            Can I obtain an unsecured business loan for my newly established
            business?
          </h4>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Absolutely, you can secure unsecured business loans for startups.
            This means that even if you haven't commenced your business
            operations or have a trading history of up to 24 months, you can
            still acquire the initial funds required to launch your business
            without pledging your personal assets, such as your home, as
            collateral.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            As a startup, it's improbable that you'll possess assets readily
            accessible to use as collateral for business loans. AMS recognizes
            the significance of avoiding the jeopardy of company assets crucial
            for business expansion, especially during the initial stages of
            establishment.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            Many of our partner lenders provide unsecured business loans
            tailored for startups, but it's crucial to be aware that you might
            encounter elevated interest rates. Unsecured startup business loans
            pose an increased level of risk for the lender, primarily because
            startups tend to be inherently unpredictable.
          </p>
          <p class="text-lg text-gray-700 leading-8 py-2">
            They lack years of proven trading history, which serves as evidence
            for secure borrowing. Nevertheless, if your UK startup requires an
            unsecured business loan and you are open to slightly higher interest
            rates, we can assist you in securing unsecured financing.
          </p>
        </div>
      </div>
      <div class="text-center mt-4">
        <NavLink to="/Contactform">
          <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
            GET A FREE QUOTE
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default BusinessLoans;
