import React from 'react'
import {  NavLink } from 'react-router-dom';


const Utilities = () => {
  return (
    <div>
      <div class=" text-center  bg-cover w-full bg-center  bg-no-repeat" style={{ backgroundImage: `url(${'../img/utility.webp'})` }}>
        <h1 class=" py-40 md:py-72 text-3xl lg:text-6xl backdrop-contrast-50 text-white font-bold">Utilities</h1>
    </div>
    <div class="lg:grid grid-cols-2 px-10 lg2:px-32 lg3:px-72 py-10">
        <div class="  hover:shadow-xl  hover:shadow-black">
            <img className='w-full' src="../img/demo2.png" alt=""/>
        </div>
        <div class=" py-5 hover:shadow-xl  hover:shadow-black   ">
            <ul class="list-disc text-lg md:px-5 text-blue-900">
                <li class="py-2">
                    We are the UK’s leading price comparison experts for commercial gas, electric and water because we make the entire process quick, simple, and stress-free.
                </li>
                <li class="py-2">
                    So many different devices and energy being used around the clock, it is vital you find a deal that helps cut costs. We are 100% independent and not tied directly to any suppliers. 
                </li>
                <li class="py-2">
                    We work with some of the biggest suppliers to negotiate the best discounts in the market.
                </li>
                <li class="py-2">
                    Get a price better then your renewal quote and fix for up to five years.
                </li>
                <li class="py-2">
                    Many of customers enjoy big savings on their business utility bills by helping them switch to better tariffs. We say if we think there is a better deal to be found elsewhere.
                </li>
                <li class="py-2">
                    We are honest about which suppliers are (and are not) on our panel.
                </li>
                <li class="py-2">
                    We never unfairly favour one supplier over another.
                </li>
                <li class="py-2">
                    With just a few details from you, we can give you instant access to an incredible range of tariffs, so you can easily compare deals in seconds.
                </li>
                <li class="py-2">
                    We will alert you when your contract is up for renewal to avoid you getting ‘rolled’ onto expensive variable rates. And when you are part of AMS family, you will unlock a most of other exclusive, money-saving services that you will LOVE.
                </li>
            </ul>
        </div>
    </div>
    <div class="text-center mt-10">
    <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                        QUOTE</button>
                    </NavLink>
    </div>
    <div class="lg2:px-32 lg3:px-72 px-5 py-10">
        <div class="py-5 hover:shadow-xl  hover:shadow-black   px-5 my-5">
            <h1 class="text-blue-900 py-2 font-bold text-2xl  lg:text-4xl">GAS</h1>
            <p class="text-lg">Keeping gas and other energy costs to a minimum can be tricky for many businesses. We could work for you an impartial energy comparison and you could save money by simply checking you are on a gas plan that suits You!.
            We are on hand throughout the entire process, with a team of energy experts to make sure your switch is as seamless and time efficient as possible. That is just one of the many reasons why so many customers choose AMS to find gas suppliers.
            Email us with your most recent bill and we will get back to you within 24 Hours with a quote! Alternatively give us a call.</p>
        </div>
        <div class="py-5  hover:shadow-xl  hover:shadow-black  px-5 my-5">
            <h1 class="text-blue-900 py-2 font-bold text-2xl lg:text-4xl">ELECTRICITY</h1>
            <p class="text-lg">Over the past seven years, the unit cost per kWh has doubled. The increase is mainly due to wholesale pricing changes, although other costs have also increased. Additional servicing and transmission charges incurred are passed directly onto the end user.
                We are on hand throughout the entire process, with a team of energy experts to make sure your switch is as seamless and time efficient as possible. That is just one of the many reasons why so many customers choose AMS to find gas suppliers.
                Email us with your most recent bill and we will get back to you within 24 Hours with a quote! Alternatively give us a call.</p>
        </div>
        <div class="py-5 hover:shadow-xl  hover:shadow-black px-5  my-5">
            <h1 class="text-blue-900 py-2 font-bold text-2xl lg:text-4xl">WATER</h1>
            <p class="text-lg">85% of businesses in the UK have not fixed into a contract for their water bills!
                As of April 2017, water for business was deregulated, and united utilities do not hold a monopoly on the market for the bills you receive, and you can now take control of your water bills!
                Email us with your most recent bill and we will get back to you within 24 Hours with a quote! Alternatively give us a call.</p>
        </div>
    </div>
    <div class="lg:grid grid-cols-3 lg2:px-32 lg3:px-72">
        <div class="px-5 py-5 mx-2 hover:bg-yellow-300 hover:shadow-2xl">
            <div class="py-2"><img class="w-20" src="../img/dollarInHand.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Guaranteed savings</p>
            <p class="text-lg">We will beat the average of the standard variable tariffs charged by the ‘Big 6’ energy suppliers.*
            </p>
        </div>
        <div class="px-5 py-5 mx-2 hover:bg-yellow-300 hover:shadow-2xl">
            <div class="py-2"><img class="w-20" src="../img/yoga.jpg" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 pt-6 py-2">Peace of mind</p>
            <p class="text-lg">We promise that your prices will always be lower than the fair price set by Ofgem.</p>
        </div>
        <div class="px-5 py-5 mx-2 hover:bg-yellow-300 hover:shadow-2xl">
            <div class="py-2"><img class="w-20" src="../img/talkBTW.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Treating you fairly</p>
            <p class="text-lg">Whether you are a new customer or already with us, compare our energy tariffs with a no-obligation energy quote.</p>
        </div>
    </div>
    <div class="text-center mt-10 py-5">
    <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                        QUOTE</button>
                    </NavLink>
    </div>
    </div>
  )
}

export default Utilities
