import React from 'react'
import {  NavLink } from 'react-router-dom';


const MerchantService = () => {
    return (
        <div>
            <div class=" text-center bg-cover w-full bg-center   bg-no-repeat" style={{ backgroundImage: `url(${'../img/merchant-services.webp'})` }}>
                <h1 class=" py-20 md:py-72 text-3xl lg:text-6xl backdrop-contrast-50 text-white font-bold">MERCHANT SERVICES</h1>
            </div>
            <div class="lg:grid lg2:px-32 lg3:px-72 grid-cols-2  ">
                <div class="lg:px-0  hover:shadow-2xl hover:shadow-amber-400 ">
                    <img src="../img/tech.jpg" alt="" />
                </div>
                <div className='hover:shadow-2xl hover:shadow-amber-400'>
                    <h4 class="py-5 px-5 text-3xl text-blue-900 font-bold">Merchant Services</h4>
                    <ul class="px-16 list-disc text-lg lg:text-lg">
                        <li class="py-2 leading-8">
                            We use the World’s most innovative card payment terminals.
                        </li>
                        <li class="py-2 leading-8">
                            The range of terminals we offer mean that you can accept card payments face to face, over the phone
                            and on the move All our terminals are PCI DSS approved.
                        </li>
                        <li class="py-2 leading-8">
                            Our smart, flexible & affordable ecommerce solution will enable you take online payments in no time.
                        </li>
                        <li class="py-2 leading-8">
                            We can easily integrate with your current payment page or setup a hassle-free integration for a new
                            order, all you must do is simply add the button and link it to your payment page.
                        </li>
                        <li class="py-2 leading-8">
                            Our portal is SSL certified & PCI DSS compliant.
                        </li>
                        <li>
                            By utilizing our experience of this complex industry, All Merchant Solutions are the best placed to
                            provide you with reliable advice, simple solutions and 7-day support, all at a tailor-made price
                            bespoke to your individual needs.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="text-center mt-10">
            <NavLink target='_blank' to="https://amsltdgroup.swoopfunding.com/login">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">APPLY
                        NOW</button>
                    </NavLink>
            </div>
            <div class="md:grid grid-cols-3 bg-gray-300 lg2:px-32 lg3:px-72 py-10">

                <div class="lg:px-10">
                    <h4 class="text-center text-4xl text-blue-900 font-bold">Go Mobile</h4>
                    <div class="py-5 px-5 lg:px-0"><img class="rounded-full w-full" src="../img/scan.jpg" alt="" /></div>
                    <p class="px-5 py-5 lg:py-20 text-lg leading-8">The latest in mobile payment technology. Go Mobile, we supply your Mobile Payment Terminal and Smart
                        Phone App. This simple to use payment solution is the perfect fit for all types of businesses. </p>
                </div>

                <div class="">
                    <h4 class="text-center text-4xl text-blue-900 font-bold">ePosNow</h4>
                    <div class="py-5 px-5 lg:px-20 "><img class="rounded-full w-full" src="../img/computer.png" alt="" /></div>
                    <p class=" py-5 px-5 lg:px-0 text-lg leading-8">One of the World’s leading Point-of-Sale vendors, ePosNow has chosen The V9 Group, developer of
                        Europe’s most advanced, and fastest-growing, payment technology, in an exclusive tie-up creating the
                        ultimate, seamless merchant solution.</p>
                </div>

                <div class="lg:px-10">
                    <h4 class="text-center text-4xl text-blue-900 font-bold">Card Terminals</h4>
                    <div class="py-5 px-5 lg:px-0"><img class="rounded-full w-full" src="../img/machine1.2.jpg" alt="" /></div>
                    <p class="px-5 py-5 lg:py-12 text-lg leading-8">Offering the latest Ingenico and PAX terminals.</p>
                </div>



                <div class="lg:px-10">
                    <h4 class="text-center text-4xl text-blue-900 font-bold">E-Commerce</h4>
                    <div class="py-5 px-5 lg:px-0"><img class="rounded-full w-full" src="../img/lapCard.jpg" alt="" /></div>
                    <p class="px-10 py-5 lg:py-24 text-lg leading-8">At the point of purchase clients are taken from your website to the secure payment page to complete the
                        transaction</p>
                </div>

                <div class="">
                    <h4 class="text-center text-4xl text-blue-900 font-bold">Spire Terminal</h4>
                    <div class="py-5 px-5 lg:px-20"><img class="rounded-full w-full" src="../img/machine2.jpg" alt="" /></div>
                    <p class="px-10 py-5 text-lg leading-8">Accept card payments from a fixed location, directly from the till or sales counter. Great for all types
                        of shops and retailers.</p>
                </div>
                
                <div class="lg:px-10">
                    <h4 class="text-center text-4xl text-blue-900 font-bold">Virtual Terminal</h4>
                    <div class="py-5 px-5 lg:px-0"><img class="rounded-full w-full" src="../img/laptop.jpg" alt="" /></div>
                    <p class="px-6 lg:px-5 py-10 lg:py-16 text-lg leading-8">A virtual terminal is a web-based payment system for processing over-the-phone transactions. It’s
                        basically a virtual card machine and you don’t even need a company website to get one.</p>
                </div>

            </div>
            <div class="text-center mt-4">
            <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                        QUOTE</button>
                    </NavLink>
            </div>

            <div class="lg2:px-32 lg3:px-72">
                <h4 class="py-5 px-5 text-4xl text-blue-900 font-bold">Payments</h4>
                <p class="px-5 leading-8">Europe’s largest and most innovative collection of products. From the market-leading retail and
                    payment providers.</p>
            </div>

            <div class="lg:grid grid-cols-3 my-10 lg2:px-32 lg3:px-72">
                <div class="px-5 py-2">
                    <h4 class="text-center text-3xl lg:text-4xl text-blue-900 font-bold">Payments by Phone</h4>
                    <div class=" py-5"><img class="rounded-full w-full" src="../img/payment1.jpg" alt="" /></div>
                    <p class="py-10 textlg leading-8 lg:text-xl ">When your customer calls, simply sign in to our Merchant Management System. Fill in their card details,
                        the amount and their billing address. That’s it. You can generate and send a receipt to them at the same
                        time.</p>
                </div>
                <div class="px-5 py-2">
                    <h4 class="text-center text-3xl lg:text-4xl text-blue-900 font-bold">Payments by Online</h4>
                    <div class=" pt-5"><img class="rounded-full w-full" src="../img/payment2.1.jpg" alt="" /></div>
                    <p class="py-14 textlg leading-8 lg:text-xl ">Log into your Merchant Management System for the details you’ll need. Enter the details in the relevant boxes in your shopping cart, and you’re good to go.</p>
                </div>
                <div class="px-5 py-2">
                    <h4 class="text-center text-3xl lg:text-4xl text-blue-900 font-bold">Payments by Email</h4>
                    <div class=" py-5"><img class="rounded-full w-full" src="../img/payment3.jpg" alt="" /></div>
                    <p class="py-5 textlg leading-8 lg:text-xl ">Enter your details securely to access your Merchant Management System. Fill in the customer information and payment amount. Create an invoice and payment link so your customer can pay instantly.</p>
                </div>
            </div>

            <div class="text-center mt-4">
            <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                        QUOTE</button>
                    </NavLink>
             
            </div>
        </div>
    )
}

export default MerchantService
