// import React from 'react'
import { NavLink } from "react-router-dom";
// import Contactform from './Contactform';
// import React from 'react'
import React, { useState } from "react";
import "firebase/storage";
// import ReCAPTCHA from 'react-google-recaptcha';

import emailjs from "emailjs-com";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  // listAll,
  // list,
} from "firebase/storage";
import { storage } from "./firebase";

import { v4 } from "uuid";

const Home = () => {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  //   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //   const handleCaptchaChange = () => {
  //     setIsCaptchaVerified(true);
  //   };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isSubmitted) {
      //   if (isCaptchaVerified) {
      emailjs
        .sendForm(
          "service_lcce4k5",
          "template_hupk1tk",
          e.target,
          "5IZopW2pUsvTML9sY"
        )
        .then((result) => {
          if (!alert("Email sent successfully:", result.text))
            document.location = "/";
          alert("Data Stored");
        })
        .catch((error) => {
          alert("plz fill the data");
          console.error("Email error:", error);
        });
    }
    setIsSubmitted(true);
    // const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };
  // } else {
  //   alert('Please verify reCAPTCHA.');
  // }

  // }
  const sendEmail1 = (e) => {
    e.preventDefault();
    if (!isSubmitted) {
      emailjs
        .sendForm(
          "service_lcce4k5",
          "template_4ovkkjt",
          e.target,
          "5IZopW2pUsvTML9sY"
        )
        .then((result) => {
          if (!alert("Email sent successfully:", result.text))
            document.location = "/";
        })
        .catch((error) => {
          alert("plz fill the data");
          console.error("Email error:", error);
        });
    }
    setIsSubmitted(true);
  };

  return (
    <div className="  ">
      <div
        class="  bg-contain  md:bg-cover md:bg-left-top     bg-no-repeat  pt-4 pb-10 md:pb-30"
        style={{ backgroundImage: `url(${"../img/pos-payments.jpg"})` }}
      >
        <div className="lg:my-56  pl-10">
          <h1 class="text-2xl md:text-5xl md:pr-40 lg:pr-56  lg2:pr-96 mt-52 py-2 font-bold text-blue-900">
            UK’s most trusted Merchant service &
          </h1>
          <h1 class="text-2xl md:text-5xl md:pr-40 lg:pr-56  lg2:pr-96  font-bold text-blue-900">
            Business Finance provide
          </h1>
          <h4 class="text-lg md:text-xl  py-2 font-bold text-yellow-400">
            Best solution for everyday business
          </h4>
          <h1 class="text-2xl lg:text-5xl  py-2 font-bold text-blue-900">
            Merchant Cash Advance
          </h1>
          <h1 class="text-2xl lg:text-5xl  py-2 font-bold text-blue-900">
            Get £2k to £500k in 24 hours
          </h1>
          <div class=" ">
            <div class="flex py-2">
              <img class="w-10 h-10" src="../img/tickMark.png" alt="" />
              <p class="pl-1">Pay back through future card sales</p>
            </div>
            <div class="flex py-2">
              <img class="w-10 h-10" src="../img/tickMark.png" alt="" />
              <p class="pl-1">Secured-Unsecured, there’s no worry</p>
            </div>
            <div class="flex py-2">
              <img class="w-10 h-10" src="../img/tickMark.png" alt="" />
              <p class="pl-1">Same day business funding</p>
            </div>
          </div>
          <div class=" mx-10 mt-4">
            <a
              target="_blank"
              href="https://amsltdgroup.swoopfunding.com/login"
            >
              <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
                APPLY NOW
              </button>
            </a>
          </div>
        </div>
      </div>

      <div class="  contrast-100 px-2 py-5 border -mt-10 mb-10 lg:mx-0  lg2:mx-20 lg3:mx-72  lg:flex  shadow-2xl  bg-white rounded-xl">
        <div class="md:col-span-2">
          <h4 class=" text-xl pl-5 font-bold text-yellow-400">
            Quick Service Request
          </h4>
          <h1 class="text-3xl pl-5 font-bold text-blue-900">
            Send Inspection Request
          </h1>
        </div>

        <form action="" className=" cols-span-3 " onSubmit={sendEmail1}>
          <div
            class=" md:flex
                     my-2  px-5 lg2:px-20 lg3:pl-40"
          >
            <div className="col-span-1  lg:mx-2 ">
              <input
                type="text"
                required
                name="user_name"
                placeholder="Enter your full name"
                class="border-2 px-2 text-lg bg-gray-100"
              />
            </div>
            <div
              className="col-span-1 md:mx-2 
                        "
            >
              <input
                required
                name="user_phone_number"
                class="border-2  px-2   text-lg bg-gray-100"
                type="number"
                placeholder="Phone number"
              />
            </div>
            <div className=" md:mx-2">
              <a href="" target="_blank">
                <button className="bg-amber-400 py-2   text-white px-12  text-sm rounded-md ">
                  SEND
                </button>
              </a>
            </div>
          </div>
        </form>
      </div>

      <div className="lg2:px-32 lg3:px-72 bg-gray-100">
        <div className="text-4xl text-blue-1000  font-bold text-center">
          Support your business with a Merchant Cash Advance
        </div>
        <p className="text-center px-5  text-xl  py-10">
          Looking for a straightforward and affordable business loan with daily
          repayments? Consider a Merchant Cash Advance, also known as a Business
          Cash Advance. This innovative financing option offers a short-term
          cash injection ranging from £3,000 to £300,000, tailored to meet your
          business's cash flow and financial needs. Unlike traditional financing
          solutions, there are no Annual Percentage Rates (APR) or hidden fees.
          With this option, you'll only agree to a transparent and manageable
          cost, which is repaid over a period of typically 6 to 9 months as a
          fixed percentage of your future credit card and debit card sales.{" "}
          <br />
        </p>
        <p className="text-center px-5 text-xl  ">
          The Merchant Cash Advance is an exciting funding solution for small
          businesses and SMEs in the UK. It has received commendation from the
          government as an alternative funding source for UK businesses, having
          already assisted thousands of entrepreneurs. This financing method is
          designed to adapt to your business's fluctuating income, ensuring that
          it accommodates both busy and slow periods. For example, during a slow
          month in card sales, your repayment is adjusted to match that month's
          sales. In essence, it keeps repayments in sync with your cash flow,
          regardless of whether times are good or challenging. The best part is
          that this unsecured business loan alternative is only repaid when you
          make sales on your card terminal (PDQ) machine.
          <br />
        </p>
        <p className="text-center  text-xl  py-10">
          When applying for a Merchant Cash Advance, rest assured that there are
          absolutely no concealed costs or fees. You can easily complete a
          straightforward online application form to receive a free,
          no-obligation quote for your business today.
        </p>
        <div className="text-center">
          <a target="_blank" href="https://amsltdgroup.swoopfunding.com/login">
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              APPLY NOW
            </button>
          </a>
        </div>
        <p className="text-center py-5 text-xs">
          Quick Decision with No Obligation
        </p>
      </div>
      <div className="text-3xl text-blue-1000   font-bold text-center py-5">
        Key Benefits of a Merchant Cash Advance
      </div>
      <div className="text-center  px-5  py-10">
        Enjoying the Advantages of a Merchant Cash Advance: Flexible Repayments
        and No Fixed Terms
      </div>

      <div className="px-2 lg2:px-32 lg3:px-72">
        <div className="md:grid grid-cols-2">
          <div>
            <div className="text-2xl text-blue-1000 font-bold text-center py-5">
              "Tailored to your credit/debit card reader":
            </div>
            <div className="flex">
              <img src="../img/bin.png" className="px-2 lg:px-10" alt="" />
              <div className=" text-center">
                You don't have to replace or modify your current card terminal
                (PDQ) machine; everything remains unchanged.
              </div>
            </div>
          </div>
          <div>
            <div className="text-2xl text-blue-1000 font-bold text-center pt-5">
              Adaptable payment options:
            </div>
            <div className="flex pt-5">
              <img src="../img/up.png" className="px-2 lg:px-10" alt="" />
              <div className="text-center pt-5  ">
                You make automatic repayments with every customer sale – No set
                payment terms are in place.
              </div>
            </div>
          </div>
        </div>

        <div className="md:grid grid-cols-2">
          <div className="">
            <div className="text-2xl text-blue-1000 font-bold text-center py-5">
              No need for collateral
            </div>
            <div className="flex">
              <img src="../img/house.png" className="px-2 lg:px-10" alt="" />
              <div className=" text-center">
                As the advance is uncollateralized, your business assets remain
                protected.
              </div>
            </div>
          </div>
          <div className="  ">
            <div className="text-2xl text-blue-1000 font-bold text-center py-5">
              Transparency in all expenses
            </div>
            <div className="flex">
              <img src="../img/nothing.png" className="px-2 lg:px-10" alt="" />
              <div className=" text-center">
                You establish a straightforward, manageable expense that you
                reimburse as a set portion of your upcoming credit and debit
                card transactions.
              </div>
            </div>
          </div>
        </div>
        <div className="md:grid grid-cols-2">
          <div className="  ">
            <div className="text-2xl text-blue-1000 font-bold text-center pt-10 -pb-5">
              Retain the full amount from your cash transactions
            </div>
            <div className="flex ">
              <img src="../img/money.png" className="px-2 lg:px-10" alt="" />
              <div className="text-center  pt-5">
                All physical cash collected by your business is yours; repayment
                is solely based on card sales.{" "}
              </div>
            </div>
          </div>
          <div className="  ">
            <div className="text-2xl text-blue-1000 font-bold text-center py-5">
              Submit your application online around the clock
            </div>
            <div className="flex">
              <img src="../img/circle.png" className="px-2 lg:px-10" alt="" />
              <div className="text-center ">
                Our application processing operates 24/7. If your busy business
                schedule makes it difficult to find time, you can apply at your
                convenience.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-32">
        <a target="_blank" href="https://amsltdgroup.swoopfunding.com/login">
          <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
            APPLY NOW
          </button>
        </a>
      </div>
      <p className="text-center py-5 text-xs">
        Quick Decision with No Obligation
      </p>

      <div className="bg-gray-100 lg2:px-32 lg3:px-72 mt-32">
        <div className="text-4xl text-blue-1000  font-bold text-center">
          An ideal funding option for any business equipped with a PDQ terminal.
        </div>
        <p className="text-center px-5  py-10 ">
          While relatively recent in the UK financial landscape, a Merchant Cash
          Advance presents a variety of enticing attributes for businesses
          within the retail, hospitality, and leisure sectors. In reality, any
          business that receives debit and credit card payments through a card
          terminal may be eligible for a Merchant Cash Advance. It's the ideal
          solution to foster your business's expansion.
        </p>
        <div className="  ">
          <div className=" text-center lg2:px-32  justify-items-center  grid md:grid-cols-2 lg:grid-cols-4 grid-rows-2    py-5">
            <div className=" ">
              <img className="py-5 px-10 " src="../img/railCar1.png" alt="" />
              <p className="">Restaurants & Takeaways</p>
            </div>
            <div className="">
              <img className="py-5 " src="../img/bottle1.png" alt="" />
              <p className=" ">Pubs, Bars & Clubs</p>
            </div>
            <div className="">
              <img className="py-5 px-10" src="../img/cart.png" alt="" />
              <p className="">Retail Shops & Online Stores</p>
            </div>
            <div className="">
              <img className="py-5 px-10" src="../img/salons.png" alt="" />
              <p className="">Hair & Beauty Salons</p>
            </div>

            <div className="">
              <img className="py-5 px-10" src="../img/carInHome.png" alt="" />
              <p className="">Garage & MOT Service Centres</p>
            </div>
            <div className=" ">
              <img className="py-5 px-8" src="../img/flower1.png" alt="" />
              <p className="text-center">Florists</p>
            </div>
            <div className="">
              <img className="py-5 px-10" src="../img/hotels1.png" alt="" />
              <p className="text-center">Hotels & B&B’s</p>
            </div>
            <div className="">
              <img className="py-5 px-10" src="../img/cup.png" alt="" />
              <p className="text-center">Cafe’s & Coffee Shops</p>
            </div>
          </div>
        </div>
        <div className="text-xl py-10 text-center">
          Explore additional instances of business categories that might find
          value in utilizing a Merchant Cash Advance
        </div>
        <div className="text-center pt-10">
          <a target="_blank" href="https://amsltdgroup.swoopfunding.com/login">
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              APPLY NOW
            </button>
          </a>
        </div>
        <div className="text-4xl text-blue-1000  font-bold text-center py-10">
          Secured Business Loans
        </div>
        <div className="text-center">
          <div className="">
            For UK entrepreneurs seeking capital to expand, using an asset as
            collateral for a business loan can be an excellent method to secure
            the required funding. Learn all the essentials of collateralized
            business financing in our Small and Medium-sized Enterprise (SME)
            handbook.
          </div>
        </div>
      </div>
      <div className="px-5  ">
        <div className="text-center text-blue-1000 py-2 font-bold text-4xl">
          How Does a Merchant Cash Advance Operate?
        </div>
        <div className="text-center pt-10">
          <NavLink to="/businessLoans">
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              READ MORE
            </button>
          </NavLink>
        </div>
        <div className="text-xl py-10 lg2:px-32 lg3:px-72 text-center">
          The procedure is swift, uncomplicated, and direct. It's trouble-free,
          ensuring you won't need to deal with intricate documentation, allowing
          you to concentrate on expanding your business.
        </div>

        <div className="lg2:px-32 lg3:px-72">
          <div className="py-10 justify-items-center md:flex">
            <img
              className="w-full lg:w-96   bg-gray-200 px-20"
              src="../img/PC.png"
              alt=""
            />
            <div className="">
              <div className="text-blue-1000 text-2xl font-bold px-5 py-5">
                Complete your online application in a matter of minutes
              </div>
              <div className="px-5">
                We only require a handful of fundamental particulars to furnish
                you with a cost-free, commitment-free estimate. If your business
                processes transactions using a (PDQ) card terminal device, you
                may be eligible for a Merchant Cash Advance of up to £300,000.
                The borrowing limit is calculated based on your average monthly
                card sales and your total transaction volume.
              </div>
            </div>
          </div>

          <div className="py-10 justify-items-center md:flex">
            <img
              className="w-full lg:w-96   bg-gray-200 px-20"
              src="../img/card.png"
              alt=""
            />
            <div className="">
              <div className="text-blue-1000 text-2xl font-bold px-5 py-5">
                Repayment Tied to Sales
              </div>
              <div className="px-5">
                Repayment is effortless and happens automatically. You establish
                a agreeable, consistent proportion (typically 10-20%) of your
                forthcoming card sales until the advance is settled. It's as
                straightforward as that! This approach is adaptable, which means
                during sluggish periods, your repayments are reduced, and during
                prosperous times, you repay more swiftly. All your physical cash
                earnings remain untouched.
              </div>
            </div>
          </div>

          <div className="py-10 justify-items-center md:flex">
            <img
              className="w-full lg:w-96   bg-gray-200 px-20"
              src="../img/clock.png"
              alt=""
            />
            <div className="">
              <div className="text-blue-1000 text-2xl font-bold px-5 py-5">
                Receive Funds in 24 Hours
              </div>
              <div className="px-5">
                Whether it's for expanding your inventory, acquiring new
                machinery, or simply an infusion of cash, a Merchant Cash
                Advance has assisted countless business clients throughout the
                United Kingdom in obtaining the financial support they require.
                Following approval, the funds could be deposited into your bank
                account within the same day.
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-10">
          <NavLink
            target="_blank"
            to="https://amsltdgroup.swoopfunding.com/login"
          >
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              APPLY NOW
            </button>
          </NavLink>
        </div>
        <div className="text-4xl text-blue-1000  font-bold text-center">
          What's the Cost of a Merchant Cash Advance?
        </div>
        <p className="text-center text-xl lg2:px-32 lg3:px-72 py-10">
          A Merchant Cash Advance represents an unsecured cash infusion that
          comes with no initial charges and no set payment conditions. You'll
          simply and automatically reimburse a portion of your future card
          earnings over a brief period, typically spanning from six to twelve
          months. With no fixed repayment terms and no penalties for late
          payments, repayments occur exclusively when a card transaction is
          processed. This means that your repayment rate will be faster during
          busier times and slower when business is quieter. <br />
        </p>
        <p className="text-center text-xl lg2:px-32 lg3:px-72 py-10">
          Each business has its own distinct characteristics, just like a
          Merchant Cash Advance. Pricing is customized to suit your business and
          its performance. To gauge the cost, take a look at the example below.
          Keep in mind, applying now can get you an instant, obligation-free
          quote!
          <br />
        </p>
        <p className="text-center text-xl lg2:px-32 lg3:px-72 py-10">
          An owner of a retail business borrows £10,000 for a renovation project
          and agrees to repay £12,000. On average, the business generates
          £20,000 per month in card transactions. The owner consents to allocate
          10% of future card transaction earnings to repay the advance. This
          would result in a monthly repayment of £2,000, and it would take
          roughly six months to settle the entire advance amount.
          <br />
        </p>
      </div>
      <div>
        <div className="grid md:grid-cols-4 lg2:px-32 lg3:px-72 justify-items-center  py-10 bg-gray-200">
          <div className="">
            <div className="text-center text-3xl py-5 text-blue-1000">
              £10,000
            </div>
            <div className="text-center text-xl text-gray-600">
              Advance amount
            </div>
          </div>
          <div className="">
            <div className="text-center text-3xl py-5 text-blue-1000">
              £2,000
            </div>
            <div className="text-center text-xl text-gray-600">
              Approximate monthly expense¹
            </div>
          </div>
          <div className="">
            <div className="text-center text-3xl py-5 text-blue-1000">
              6 Months
            </div>
            <div className="text-center text-xl text-gray-600">
              Anticipated repayment duration
            </div>
          </div>
          <div className="">
            <div className="text-center text-3xl py-5 text-blue-1000">
              £12,000
            </div>
            <div className="text-center text-xl text-gray-600">
              Overall sum to be reimbursed
            </div>
          </div>
        </div>
      </div>

      <div className="lg2:px-32 lg3:px-72 px-5">
        <div className="text-center py-2">
          This represents a mean value and encompasses the comprehensive advance
          expenditure.
        </div>
        <div className="text-center py-2">
          Pricing is customized to precisely match your business requirements,
          and sums can fluctuate in accordance with your performance. The
          expenses will be transparent and clarified before you commit to the
          arrangement.
        </div>
        <div className="text-center py-10">
          Get a <b>free, no-obligation quote</b> today – it only takes a minute!
        </div>

        <div className="text-center pt-10">
          <NavLink
            target="_blank"
            to="https://amsltdgroup.swoopfunding.com/login"
          >
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              APPLY NOW
            </button>
          </NavLink>
        </div>
        <p className="text-center py-5 text-xs">
          Quick Decision with No Obligation
        </p>
      </div>
      <div class=" bg-gray-100  ">
        <div className="grid md:grid-cols-2 px-10 py-5 lg2:px-32 lg3:px-72 lg:grid-cols-4">
          <div class="col-span-1 border">
            <img class="px-5 py-2 w-full" src="../img/comm1.1.png" alt="" />

            <h4 class=" font-bold px-10 text-xl">Utilities</h4>
            <p class="text-lg  py-5 px-10">
              AMS is a UK-based price comparison expert for commercial gas,
              electric, and water, offering quick, simple solutions.
            </p>
            <div className=" grid grid-rows-2 lg2:pt-12 text-center pt-10">
              <NavLink target="_blank" to="/Utilities">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  READ MORE
                </button>
              </NavLink>
              <NavLink target="_blank" to="/Contactform">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  GET A FREE QUOTE
                </button>
              </NavLink>
            </div>
          </div>
          <div class="grid grid-rows- border">
            <img class="px-5 py-2 w-full" src="../img/comm2.1.png" alt="" />

            <h4 class="px-10 font-bold text-xl ">Merchant Services</h4>
            <p class="text-lg  py-5 px-10">
              Merchant Solutions offers PCI DSS-approved, SSL-certified
              ecommerce payment terminals.
            </p>
            <div className=" grid grid-rows-2 text-center pt-14">
              <NavLink target="_blank" to="/MerchantService">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  READ MORE
                </button>
              </NavLink>
              <NavLink target="_blank" to="/Contactform">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  GET A FREE QUOTE
                </button>
              </NavLink>
            </div>
          </div>
          <div class="col-span-1 border">
            <img class="px-5 py-2 w-full" src="../img/conm3.1.jpg" alt="" />

            <h4 class="px-10 font-bold text-xl ">Telecommunications</h4>
            <p class="text-lg  py-5 px-10">
              AMS is the partner with one of the biggest Telecommunication
              suppliers, bOnline.
            </p>
            <div className="grid grid-rows-2 text-center md:pt-20">
              <NavLink target="_blank" to="/Telecommunications">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  READ MORE
                </button>
              </NavLink>
              <NavLink target="_blank" to="/Contactform">
                <button className="bg-amber-400 py-2  my-2 text-white px-5  text-sm rounded-md ">
                  GET A FREE QUOTE
                </button>
              </NavLink>
            </div>
          </div>
          <div class="col-span-1 border">
            <img class="px-5 py-2 w-full" src="../img/comm4.1.jpg" alt="" />

            <h4 class="px-10 font-bold text-xl ">Business funding solution</h4>
            <p class="text-lg  py-5 px-10">
              AMS is providing best funding solution for your business
            </p>
            <div className=" grid grid-rows-2 text-center lg2:pt-32 pt-20">
              <NavLink target="_blank" to="/BusinessFundingSolution">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  READ MORE
                </button>
              </NavLink>
              <NavLink target="_blank" to="/Contactform">
                <button className="bg-amber-400 py-2 my-2 text-white px-5  text-sm rounded-md ">
                  GET A FREE QUOTE
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 lg2:px-32 lg3:px-72  bg-blue-1000 text-white text-lg  bg-blue-1000 ">
        <div className="pt-5 grid  md:grid-cols-6">
          <div className="md:col-span-4">
            <div className="   font-bold text-3xl md:text-4xl ">
              Get a Business funding solution​ quote today
            </div>
            <div className="py-5 bg-blue-1000">
              <div className="grid gap-1  bg-blue-1000 md:grid-cols-3">
                <div class="flex py-2">
                  <img class="w-8   h-8" src="../img/tickMark1.png" alt="" />
                  <p class="pl-1">Borrow up to £500k</p>
                </div>
                <div class="flex py-2">
                  <img
                    class="w-8 bg-blue-1000 h-8"
                    src="../img/tickMark1.png"
                    alt=""
                  />
                  <p class="pl-1">Quick Decision</p>
                </div>
                <div class="flex py-2">
                  <img
                    class="w-8 bg-blue-1000 h-8"
                    src="../img/tickMark1.png"
                    alt=""
                  />
                  <p class="pl-1">No Obligation</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-span-2 text-center pt-10">
            <NavLink
              target="_blank"
              to="https://amsltdgroup.swoopfunding.com/login"
            >
              <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
                APPLY NOW
              </button>
            </NavLink>
          </div>
        </div>
      </div>
      <div>
        <div className="text-4xl text-blue-1000 pt-32 font-bold text-center">
          Frequently asked questions
        </div>
        <p className="text-center text-lg lg2:px-32 lg3:px-72 py-5">
          For more information, please visit our{" "}
          <a href="">Frequently Asked Questions</a> page. <br />
        </p>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            How Much Can My Business Access?
          </div>
          <p className=" text-lg py-2">
            The amount available to your business hinges on your average monthly
            card sales. Consequently, the more revenue your card machine
            processes, the greater your borrowing capacity. For instance, if
            your business maintains a monthly average of £5,000 in sales through
            your PDQ machine or merchant gateway, you may be eligible for
            funding equivalent to that amount through a merchant cash advance.
            In specific cases, and contingent upon risk assessment, the funding
            offer could even reach up to 150% of your monthly card sales
            turnover, thereby potentially securing a larger sum. <br />
          </p>
          <p className=" text-lg py-2">
            The lump sum of credit accessible typically ranges between £3,000
            and £300,000, with some instances involving even higher sums. <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            What Is the Loan Duration?
          </div>
          <p className=" text-lg py-2">
            A merchant cash advance is a short-term financial solution. The
            repayment period depends on your business's performance in credit
            card and debit card transactions. During slower sales periods, your
            repayments decrease, while in prosperous times, they increase. Usual
            repayment timelines span from 6 to 9 months, but they can range from
            as short as 4 months to as long as 18 months. After establishing a
            positive repayment track record, you might have the opportunity to
            extend your borrowing amount and term. This, of course, depends on
            your business's financial viability and repayment history. <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            Qualification for Your Business
          </div>
          <p className=" text-lg py-2">
            To be eligible, your business must operate as a limited company,
            partnership, or sole trader within the United Kingdom. Accepting
            card payments from customers is a prerequisite, whether through a
            PDQ machine or online sales via your e-commerce merchant gateway
            provider. <br />
          </p>
          <p className=" text-lg py-2">
            Businesses from various sectors qualify for a business cash advance
            as long as they process card payments from their customers.
            Industries such as hospitality, retail, shops, restaurants, hotels,
            cafes, MOT centers, and garages are common examples. Many online
            e-commerce retail businesses also make use of this type of
            commercial financing.
            <br />
          </p>
          <p className=" text-lg py-2">
            Ideally, your business should have been in operation for
            approximately three months and should generate monthly card sales
            exceeding £2,500.
            <br />
          </p>
          <p className=" text-lg py-2">
            Do not fret if you cannot meet these criteria; we will strive to
            find a solution that works for you.
            <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            Factor Rate and Its Calculation
          </div>
          <p className=" text-lg py-2">
            A factor rate is employed by lenders to ascertain the total
            repayment amount. This is a straightforward calculation where the
            funding amount is multiplied by the factor rate (typically falling
            between 1.1 and 1.5). <br />
          </p>
          <p className=" text-lg py-2">
            For instance, suppose your business borrows £5,000 based on its
            sales volume, and the lender sets the factor rate at 1.25. The
            calculation is as follows: (£5,000 x 1.25 = £6,250 total
            repayable/total finance cost).
            <br />
          </p>
          <p className=" text-lg py-2">
            The factor rate is computed based on your business's trading
            performance, its sector, and other risk factors linked to the
            business. Through our service, you will receive quotes from lenders
            offering competitive factor rates, granting you access to the most
            favorable rates available.
            <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            Fixed Monthly Repayments
          </div>
          <p className=" text-lg py-2">
            There are no set monthly repayments. This isn't your conventional
            loan, so there are no fixed terms or APR. You only repay a small
            fraction of your future credit and debit card sales until the
            advance is fully settled. This model accommodates seasonal
            businesses where card sales can experience significant fluctuations.
            <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            Impact on Credit Rating
          </div>
          <p className=" text-lg py-2">
            We do not conduct credit checks during the application process;
            however, the provider you are matched with may perform a credit
            assessment through a major credit bureau. A missed or delayed
            payment may potentially influence your credit score.
            <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            Accessing a Merchant Cash Advance with Bad Credit
          </div>
          <p className=" text-lg py-2">
            Having a less-than-perfect credit history should not deter you from
            applying for a cash advance. In certain situations, lenders might be
            open to considering your application despite a subpar credit
            history. The key is to proceed with your application and not be
            discouraged. Furthermore, if you are aware of any blemishes in your
            credit history, you can address them when discussing your
            application with the lender. Being forthcoming with additional
            information can enhance your application's prospects.
            <br />
          </p>
        </div>
        <div className="lg2:px-32 lg3:px-72 px-5 pt-10">
          <div className="text-2xl text-blue-1000 font-bold ">
            Merchant Cash Advance vs. Business Cash Advance
          </div>
          <p className=" text-lg py-2">
            A 'merchant cash advance' and a 'business cash advance' are
            synonymous. They refer to the same financial product and do not
            differ in terms of funding options; they merely employ varying
            industry terminology.
            <br />
          </p>
        </div>
      </div>
      <div className="grid text-center  md:grid-cols-3 lg2:px-32 lg3:px-72 py-10 bg-yellow-50 divide-x-2 mt-32">
        <div className="px-5  py-5">
          <p className="text-2xl font-bold text-yellow-400 py-2">
            We Are Business Who Cares, And it Shows
          </p>
          <p>OVER 540 “5 STARS” REVIEWS AND CLIMBING</p>
        </div>
        <div className="flex justify-center text-center py-5 px-4">
          <img className=" w-14 lg:w-16   md:w-12" src="../img/G.png" alt="" />
          <div className="px-2">
            <p className="font-bold text-black text-xl py-2">
              Rate Us on Google
            </p>
            <p>1,248 reviews</p>
          </div>
        </div>

        <div className="px-10  py-5">
          <NavLink to="/Contactform">
            <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
              RATE US
            </button>
          </NavLink>
        </div>
      </div>
      <div className="text-center bg-blue-900 bg-blue-1000 py-20">
        <p className="text-yellow-400 font-bold text-xl py-2">
          Don't Know Where To Start With?
        </p>
        <p className="text-4xl text-white font-bold">
          CONNECT WITH US FOR ALL YOUR REQUIREMENTS
        </p>
      </div>
      <div className="lg:grid grid-cols-2">
        <div className="bg-white divide-y-2 lg2:px-36 lg3:px-56 px-10 pb-10 pt-40">
          <div className="py-5">
            <p className="text-yellow-400 text-xl font-bold py-2">Offices</p>
            <p className="text-4xl font-bold text-blue-1000">Head Office</p>
            <p className="py-2">Havelock Hub</p>
            <p className="py-2">14 Havelock Place</p>
            <p className="py-2">Harrow HA1 1LJ</p>
          </div>
          <div className="py-5">
            <p className="py-4">Phone: +44 3333 444 941</p>
            <p>Email: office@example.com</p>
          </div>
        </div>
        <div className="lg2:pr-36 px-5 lg3:pr-56  ">
          <form
            enctype="multipart/form-data"
            method="post"
            onSubmit={sendEmail}
            action=""
            className=" lg:-mt-16 bg-white lg:px-10 py-10"
          >
            <p className="text-yellow-400 text-xl font-bold ">
              Free Consultation
            </p>
            <p className="text-4xl font-bold text-blue-1000 py-2">
              GET A FREE QUOTE
            </p>

            <div className="mt-8">
              <label className="">Full Name</label>
              <br />
              <input
                type="text"
                required
                name="user_name"
                className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                placeholder="Enter your full name"
              />
              <br />
            </div>

            <div className="mt-8">
              <label className="">Email</label>
              <br />
              <input
                type="email"
                required
                name="user_email"
                className="border    rounded-lg  p-2 mt-2 mb-2 w-full"
                placeholder="Enter your full Email"
              />
              <br />
            </div>
            <div className="mt-8">
              <label className="">Phone Number</label>
              <br />
              <input
                type="number"
                required
                name="user_phone_number"
                className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                placeholder="Enter your Phone Number"
              />
              <br />
            </div>

            <div className="mt-8">
              <label>Service Description</label>
              <br />
              <input
                name="user_service_description"
                required
                className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                placeholder="Service Description"
              />
              <br />
            </div>

            <div className="mt-8">
              <label>Comment or Message</label>
              <br />
              <textarea
                row="10"
                required
                name="message"
                placeholder="Message"
                className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
              ></textarea>
            </div>

            <div className="App">
              <input
                type="file"
                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}
              />
            </div>
            {/* //     <div className=" my-14 "> */}
            {/* <ReCAPTCHA sitekey="6LdBo5YnAAAAAIa_nmquSmJH_qV_F20uy6LEwka0" onChange={handleCaptchaChange} /> */}
            {/* <div className="">
                            <NavLink to="/Contactform">
                                <button
                                    className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                                    QUOTE</button>
                            </NavLink>

                        </div>
                    </form> */}
            <div className=" my-14 ">
              {/* <ReCAPTCHA sitekey="6LdBo5YnAAAAAIa_nmquSmJH_qV_F20uy6LEwka0" onChange={handleCaptchaChange} /> */}

              <a href="" target="_blank">
                <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
                  GET A FREE QUOTE
                </button>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
{
  /* <form enctype="multipart/form-data" method="post" onSubmit={sendEmail} >
                        <div className="lg:grid gap-2 grid-cols-2">
                            <div className="col-span-1 px-2">
                                <label>First Name</label>
                                <input type="text" required name='user_last_name' className="border   rounded-lg p-2 mt-2 mb-2 w-full" /><br />
                            </div>



                            <div className="col-span-1 px-2">
                                <label className=" ">Last Name</label>
                                <input type="text" name='user_last_name' className="border   rounded-lg  p-2 mt-2 mb-2 px-5 w-full" /><br />
                            </div>
                        </div>
                        <div className="mt-8">
                            <label className="">Email</label><br />
                            <input type="email" required name='user_email' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                        </div>

                        <div className="mt-8">
                            <label className="">Email</label><br />
                            <input type="email" required name='user_email' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                        </div>

                        <div className="mt-8">
                            <label className="">Phone Number</label><br />
                            <input type="number" required name='user_phone_number' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                        </div>

                        <div className="mt-8">
                            <label>Subject</label><br />
                            <input name='user_subject' required className="border   rounded-lg  p-2 mt-2 mb-2 w-full" /><br />
                        </div>

                        <div className="mt-8">
                            <label>Comment or Message</label><br />
                            <textarea row="10" required name='message' placeholder='Message' className="border   rounded-lg  p-2 mt-2 mb-2 w-full"></textarea>
                        </div>
                        {/* <div className="App">
                      <input
                        type="file" 
                        // onChange={(event) => {
                          // setImageUpload(event.target.files[0]);
                        // } }
                      />
                    </div> */
}
//     <div className=" my-14 ">
//         {/* <ReCAPTCHA sitekey="6LdBo5YnAAAAAIa_nmquSmJH_qV_F20uy6LEwka0" onChange={handleCaptchaChange} /> */}

//         <a href="" target="_blank">
//             <button type='submit' className=" rounded-lg  bg-gray-200  hover:bg-black hover:text-white py-3 px-4">Submit
//                 your CV
//             </button>
//         </a>
//     </div>
// </form> */}
