// import React from 'react'
import React, { useState } from 'react';
import 'firebase/storage';
// import ReCAPTCHA from 'react-google-recaptcha';


import emailjs from 'emailjs-com';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  // listAll,
  // list,
} from "firebase/storage";
import { storage } from "./firebase";

import { v4 } from "uuid";




const Contactform = () => {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  // const handleCaptchaChange = () => {
  //   setIsCaptchaVerified(true);
  // };



  const sendEmail = (e) => {
    e.preventDefault();
    // if (isCaptchaVerified) {
    if (!isSubmitted) {
      //
        
        emailjs.sendForm('service_lcce4k5', 'template_hupk1tk', e.target, '5IZopW2pUsvTML9sY')
          .then((result) => {
            if (!alert("Data Stored")) document.location = "/"
           

            alert('Email sent successfully:', result.text);
          })
          .catch((error) => {
            alert("plz fill the data");
            console.error('Email error:', error);
          });
        // const uploadFile = () => {
       



      //
    };
    setIsSubmitted(true)
    if (imageUpload == null) return;

        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
           
            setImageUrls((prev) => [...prev, url]);
          });
        });
      // } else {
      //     alert('Please verify reCAPTCHA.');
      //   }
   
    
   


  }



  return (
    <div>

      <div class="container my-24 mx-auto md:px-6">
        {/* <!-- Section: Design Block --> */}
        <section class="mb-32">
          <div class="relative h-[100px] overflow-hidden bg-cover bg-[50%] bg-no-repeat " ></div>
          <div class="container px-6 md:px-12">
            <div
              class="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
              <div class="flex flex-wrap">
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 ">
                  {/* onSubmit={sendEmail}  */}
                  <h1 className='text-xl  text-amber-500  font-bold'>Free Consultation </h1>
                  <h1 className='text-6xl text-blue-1000 font-bold'>FEEL FREE TO INQUIRE</h1>
                  <form enctype="multipart/form-data" method="post" onSubmit={sendEmail} >
                    <div className="mt-8">
                      <label className="">Full Name</label><br />
                      <input type="text" required name='user_name' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Enter your full name" /><br />
                    </div>
                    <div className="mt-8">
                      <label className="">Phone Number</label><br />
                      <input type="number" required name='user_phone_number' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Enter your Phone Number" /><br />
                    </div>

                    <div className="mt-8">
                      <label className="">Email</label><br />
                      <input type="email" required name='user_email' className="border    rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Enter your full Email" /><br />
                    </div>
                    <div >
                      <label className=' font-bold'>Last Utility Bill/Merchant Statement</label><br />
                      <div className="border border-gray-400  border-dashed  rounded-lg  py-10 px-2  mt-2 mb-2 w-full" >


                        <input
                          type="file"
                          onChange={(event) => {
                            setImageUpload(event.target.files[0]);
                          }}
                        />
                      </div>


                    </div>


                    <div className="mt-8">
                      <label>Service Description</label><br />
                      <input name='user_service_description' required className="border   rounded-lg  p-2 mt-2 mb-2 w-full" placeholder="Service Description" /><br />
                    </div>

                    <div className="mt-8">
                      <label>Comment or Message</label><br />
                      <textarea row="10" required name='message' placeholder='Message' className="border   rounded-lg  p-2 mt-2 mb-2 w-full" ></textarea>
                    </div>


                    <div className=" my-14 ">
                      {/* <ReCAPTCHA sitekey="6LfOvMEnAAAAAIPA9T0MOjNib6qm2TEtI9YIlwsG" onChange={handleCaptchaChange} /> */}

                      <a href="" target="_blank">
                        <button
                          className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                          QUOTE</button>
                      </a>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Contactform

