import { NavLink } from 'react-router-dom';
import React from 'react'

const ContactUs = () => {
    return (
        <div>
            {/* <div  class="w-full  h-auto lg:px-20  lg:py-96  bg-gray-300" > */}

            <div class="w-full bg-contain   md:bg-center   md:bg-cover bg-left-top contrast-75 px-10    bg-no-repeat h-auto  lg:px-20  pt-80 lg:py-96 bg-gray-300" style={{ backgroundImage: `url(${'../img/pos-payments.jpg'})` }}>
                {/* <div class="py-5"><img src="../img/logo.png" alt=""/></div> */}
                <h1 class=" text-blue-950 pt-5 font-bold text-3xl lg:text-6xl">Contact Us</h1>

                <p class="text-blue-950 text-lg lg:text-lg py-3">If you require any further information, please don’t hesitate
                    to contact one of our team today using the details below. We love to chat!</p>
            </div>
            <div class="grid md:grid-cols-2 py-5 lg:py-32 lg:px-20">
                <div class="col-span-1 px-5  lg:px-10 lg2:px-20 lg3:px-40"><img class="w-full" src="../img/Mobile.jpg" alt="" /></div>
                <div class="col-span-1 py-5 px-5 lg:px-0">
                    <h4 class="text-yellow-400 font-bold text-lg">Get In Touch</h4>
                    <h1 class="text-blue-900 font-bold text-3xl py-2">Any Question?</h1>
                    <h1 class="text-blue-900 font-bold text-3xl py-0">Write Down And Send Us</h1>
                    <form action="" class="py-5" >
                        <input class="px-5 py-3 w-full border-4 my-2 bg-gray-100 text-lg" type="text" placeholder="Enter your full name" />
                        <input class="px-5 py-3 w-full border-4 my-2 bg-gray-100 text-lg" type="number" placeholder="Phone number" />
                        <input class="px-5 py-3 w-full border-4 my-2 bg-gray-100 text-lg" type="email" placeholder="Your email" />
                        <input class="px-5 py-3 w-full border-4 my-2 bg-gray-100 text-lg" type="text" placeholder="Service Description" />
                        <textarea class="px-5 py-3 w-full border-4 my-2 bg-gray-100 text-lg" cols="30" rows="5" placeholder="Message"></textarea>
                        <div class="text-white mt-5">
                            <NavLink to="/Contactform">
                                <button
                                    className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE QUOTE</button>
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>
            <div class="grid md:grid-cols-3 py-10 lg2:px-32 lg3:px-72">
                <div class="col-span-1 px-5 my-10 lg:my-0 mx-6 border-2 shadow-2xl ">
                    <div className=' py-2 flex'>  <img class="w-10 " src="../img/line.png" alt="" />
                        <h4 class=" px-2 py-1 text-blue-900 font-bold text-xl">Main Office</h4>
                    </div>
                    <div className=' pt-10 text-center  flex' >
                        <img class="w-10  " src="../img/sendArrow.png" alt="" />
                        <p class="py-2 px-2 font-bold">AMSL Group</p>
                    </div>
                    <p class="leading-8 text-center">Havelock Hub 14 Havelock Place Harrow  HA1 1LJ</p>
                </div>
                <div class="col-span-1 px-5 my-10 lg:my-0 mx-6 border-2 shadow-2xl ">
                    <div className=' py-2 flex'>  <img class="w-10 "src="../img/line.png" alt="" />
                        <h4 class=" px-2 py-1 text-blue-900 font-bold text-xl">Make a Call</h4>
                    </div>
                    <div className=' pt-10 text-center  flex' >
                        <img class="w-10  "src="../img/smCall.png" alt=""  />
                        <p class="py-2 px-2 font-bold">03333 444 941</p>
                    </div>
                    <p class="leading-8 text-center">Mon - Sat: 09am - 08pm</p>
                </div>
                <div class="col-span-1 px-5 my-10 lg:my-0 mx-6 border-2 shadow-2xl ">
                    <div className=' py-2 flex'>  <img class="w-10 " src="../img/line.png" alt="" />
                        <h4 class=" px-2 py-1 text-blue-900 font-bold text-xl">Send a Mail</h4>
                    </div>
                    <div className=' pt-10 text-center  flex' >
                        <img class="w-10  " src="../img/smMail.png" alt="" />
                        <p class="py-2 px-2 font-bold">AMSL Group</p>
                    </div>
                    <p class="leading-8 text-center">info@amsltdgroup.com</p>
                </div>
                
                
            </div>
            <div class=" lg:py-20 lg2:px-32 lg3:px-72">
        <iframe class="w-full  "
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.3266411533227!2d-0.33758462300816044!3d51.580576605354395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487613be81525695%3A0xa6f223a4a9be4cf0!2sHavelock%20Hub%20Serviced%20Offices!5e0!3m2!1sen!2sin!4v1692619276338!5m2!1sen!2sin"
            width="600" height="450"  allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
        </div>
    )
}

export default ContactUs
