// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBN2BEhFro4VcfYxQARw1oriSKoDxh6BQg",
  authDomain: "my-data-59944.firebaseapp.com",
  projectId: "my-data-59944",
  storageBucket: "my-data-59944.appspot.com",
  messagingSenderId: "728727543854",
  appId: "1:728727543854:web:cd429699067bf3fea9ffbf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);