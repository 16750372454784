import React from "react";

const MerchantCashAdvance = () => {
  return (
    <div>
      <div
        class=" text-center  bg-cover bg-center   bg-no-repeat"
        style={{
          backgroundImage: `url(${"../img/Merchant_Cash-Advance.jpg"})`,
        }}
      >
        <h1 class=" py-40 md:py-72 text-3xl  lg:text-6xl text-white font-bold"></h1>
        <img src="" alt="" />
      </div>

      <div class="px-5 lg:px-32 xl:px-72">
        <h1 class="text-4xl lg:text-5xl text-blue-900 font-bold">
          Merchant Cash Advance services
        </h1>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            What is a merchant cash advance?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A merchant cash advance, sometimes referred to as a business cash
            advance or PDQ loan, is a brief, unsecured cash infusion obtained
            based on your business's anticipated future credit and debit card
            sales.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            In contrast to a typical unsecured business loan, there are no fixed
            monthly payments to be concerned about because a portion of the
            funds is automatically repaid each time you conduct a transaction on
            your card machine.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            It also implies that your repayment timetable mirrors the
            fluctuations in your business's performance. The more card payments
            you handle, the faster your advance is settled, and conversely.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            This financial agreement is not backed by any of your company's
            assets, making it unsecured. Instead, the funding is provided based
            on your business's prospective credit and debit card sales.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            Therefore, a merchant cash advance is appropriate for any UK
            business that maintains a merchant account and conducts card
            transactions using a PDQ card machine or a merchant gateway
            facility.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            How does a merchant cash advance operate?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A merchant cash advance is a simple and adaptable funding option. It
            permits businesses to acquire a sum of money from a lender and
            subsequently make repayments conveniently using their forthcoming
            customer card transactions.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            The lender typically provides a capital amount matching your
            company's monthly card sales turnover. Concerning the repayment of
            the borrowed funds, a prearranged percentage (typically about 10%)
            is subtracted from each of your card sales until the advance is
            completely repaid.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            In this scenario, if you conducted a £100 transaction through your
            card machine, you would retain £90, and the remaining £10 would be
            automatically remitted to the lender via your merchant bank account.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Here’s an example of a typical repayment scenario:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A small, locally-owned retail store borrows £5,000 to purchase
            inventory and commits to reimbursing £6,000 at a factor rate of 1.2.
            The proprietor also consents to allocate 10% of the shop's card
            sales for repaying the loan.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            The store generates an average of £10,000 in monthly card sales and
            is obligated to reimburse £1,000 (10%) each month until the loan is
            entirely settled.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Since there is no set duration, it is anticipated that the business
            will settle the entire advance sum of £6,000 in around six months.
            The repayment timeframe is adaptable and could be briefer or
            extended, contingent on sales. Keep in mind that repayment only
            occurs when you make sales to customers.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            What is the maximum loan amount available?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Normally, the majority of advance sums fall within the range of
            £2,500 to £300,000. The borrowing capacity of your business is
            linked to its average monthly card turnover. The greater the sales
            you handle, the more substantial the cash advance you can access.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Our calculator for merchant cash advances is intended to provide you
            with a projection of the sum you could potentially borrow and the
            overall amount to be repaid, which encompasses factor rate expenses.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            It is meant to serve as a reference for comparing against any quotes
            you may have already obtained or as an initial reference point for
            your initial inquiries.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            You can compare your merchant cash advance rates by using our
            calculator.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            What is a factor rate?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A factor rate, expressed as a decimal figure, is employed to
            determine the total cost of the advance instead of using a
            conventional interest rate percentage.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            The factor rate will fluctuate considerably based on your business
            sector, risk factors, and trading history, but it typically falls
            within the range of 1.1 to 1.5.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            For instance, if you borrow £10,000 and the factor rate provided is
            1.2, you will repay a total of £12,000. The fees are determined
            simply by multiplying the borrowed amount by the factor rate
            (£10,000 x 1.20 = £12,000). In this scenario, the cost will be
            £2,000.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            Is a merchant cash advance a loan?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            While it may be called a card processing loan and a PDQ loan, a
            merchant cash advance is, in reality, not a loan. It operates by
            providing an advance based on your business's projected income and
            involves selling future debit and credit card earnings to the
            lender.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            You can enjoy a rapid injection of funds without the typical
            constraints linked to conventional loans. There might be no credit
            assessment, and approval is usually swift, often within the same day
            of applying.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            What advantages does a merchant cash advance offer?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A merchant cash advance provides numerous benefits to small and
            medium-sized enterprises (SMEs) in the UK. As its popularity grows,
            an increasing number of businesses are turning to this innovative
            financing option. The advantages of business cash advances
            encompass:
          </p>
          <ul class="list-disc  text-gray-700 leading-8 px-10 lg:px-20 py-5">
            <li>
              Adjustable payments tied to upcoming credit and debit card
              transactions
            </li>
            <li>Swift access to capital for your business</li>
            <li>No accrued interest on the borrowed funds</li>
            <li>Evaluation of all business credit scores</li>
            <li>Reduced risk of failing to meet repayment obligations</li>
          </ul>
          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Adaptable repayments linked to forthcoming credit and debit card
            transactions:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Under this funding model, your repayments align with the processing
            of customer card payments. Payments are contingent on your card
            sales, ensuring you retain the full proceeds from cash sales.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Swiftly access funds for your business:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Merchant cash advance providers possess the ability to rapidly
            evaluate your company's monthly card sales turnover. Thanks to their
            swift decision-making and high approval rates, you can secure the
            advance within a matter of days.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Zero interest levied on the funds borrowed:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Right from the start, the lender will provide a clear picture of the
            overall borrowing cost, determined by the agreed-upon factor rate.
            There are no APR, concealed fees, or additional charges linked to
            the borrowing.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Protection of business assets:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A merchant cash advance falls under the category of unsecured
            funding. Consequently, there's no need for your business assets to
            serve as collateral for the advance. This can be advantageous for
            numerous businesses, particularly those with limited assets.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            No necessity for a business plan:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            In contrast to traditional business finance lenders that may request
            a business plan, business cash advance lenders do not require one.
            They assess your past card performance through your merchant
            statements and can forecast your future card sales based on this
            information.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Reduced paperwork and simplified process:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Certain lenders have the capability to digitally access your
            merchant account statements using the provided merchant ID. This
            streamlines the process, eliminating the need for you to send the
            merchant statements via email or traditional mail.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Borrow from £2,500 to £300,000:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Your eligible cash advance amount is typically determined by your
            monthly card turnover, allowing you to secure up to 150% of your
            average monthly card sales.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            What are the drawbacks of a merchant cash advance?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            As with any business financing option, specific commercial loan
            products may be better suited to some businesses than others. The
            primary drawbacks primarily pertain to businesses that do not meet
            the eligibility criteria for the merchant cash advance product.
          </p>
          <ul class="list-disc  text-gray-700 leading-8 px-10 lg:px-20 py-5">
            <li>
              Inappropriate for businesses that don't accept customer card
              payments
            </li>
            <li>
              Not suitable for startups or businesses with less than three
              months of card sales
            </li>
          </ul>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Unfortunately,, if your business doesn't handle card payments, it
            won't meet the criteria for eligibility. If it does, you must
            achieve a specific monthly turnover threshold for your application
            to be accepted, which typically falls in the range of £2,500 per
            month.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Certain business cash advance providers may necessitate that you
            switch from your current card terminal provider to a different
            merchant processor. Nevertheless, in most cases, if a transfer is
            needed, it will be coordinated on your behalf as an integral aspect
            of your funding arrangement.
          </p>
        </div>
        <div>
          <h4 class="py-5 text-black font-bold text-3xl">
            If you find that you don't meet the eligibility criteria for a
            merchant cash advance, don't worry. What's the next step?
          </h4>
          <p class="text-lg leading-8 text-gray-600 py-4">
            There are situations where a business might not meet the
            requirements for a merchant cash advance. For example, you may have
            a card machine, but your monthly card sales are currently below the
            lender's threshold. Alternatively, if your business hasn't been
            operating for a sufficient duration, it might not meet the necessary
            criteria at this time.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            The positive news is that both of these situations are temporary.
            When your business has a trading history of more than three months,
            it's advisable to reapply.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            Conversely, if your current monthly turnover falls below the £2,500
            threshold, you might explore strategies to boost your card sales
            within your business.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            A merchant cash advance provides unparalleled flexibility in how you
            allocate the funds. You can utilize the money for various purposes,
            whether it's for expanding your business, managing unexpected
            expenses, or enhancing your cash flow. Here are a few illustrations
            of how this funding can benefit your company:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Hiring and training new employees:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            When it's time to grow your business, respond to seasonal spikes in
            demand, or enhance your workforce, you'll likely need to recruit and
            train new staff. A merchant cash advance can provide the necessary
            funds for staff development or expansion without the complexities
            associated with a traditional bank loan.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Buying inventory:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Maintaining a well-stocked inventory is crucial for managing cash
            flow in your business. Various challenges, such as late payments
            from clients or unforeseen financial issues, can create difficulties
            in this area.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A merchant cash advance can provide the necessary capital to
            purchase inventory when you need it the most. Whether you're looking
            to expand your product offerings or facing financial constraints,
            this funding option can help you keep your stock at optimal levels.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Upgrading equipment:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            As your business matures, you might find the need to modernize or
            replace existing equipment. However, you might not have the
            necessary funds readily available. In such cases, a merchant cash
            advance can provide the capital required to upgrade your equipment,
            ensuring your business operations remain efficient and competitive.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Renovating or expanding your location:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            In the event of rapid business growth, especially in customer-facing
            or hospitality sectors, you may find the need to enhance or extend
            your existing premises. This is crucial to accommodate the increased
            demand and maintain a high level of customer service. A merchant
            cash advance can provide the financial support needed to renovate or
            expand your business location.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Managing fluctuations in your company's cash flow:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            Every business, especially those in the hospitality sector,
            experiences fluctuations in cash flow, often due to seasonal demand.
            During periods of reduced cash flow, your business may require
            additional funding to ensure smooth operations.
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            A merchant cash advance alleviates the financial burden on
            businesses by offering a cash advantage. During periods of reduced
            sales, your repayments are also lower, providing financial relief.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4">
            Investing in advertising and marketing is essential for promoting
            your business. Utilizing your funding for advertising campaigns can
            help you maintain strong relationships with your current customers
            and attract new ones, contributing to the growth of your business.
          </p>

          <p class="text-lg leading-8 text-gray-600 py-4 font-bold">
            Handle unexpected repair costs:
          </p>
          <p class="text-lg leading-8 text-gray-600 py-4">
            When your business faces unexpected repair expenses due to events
            like accidental damage, aging equipment, or criminal activities,
            such as fire, flood, or vandalism, a merchant cash advance can
            provide the financial support needed to swiftly address these issues
            and maintain your business operations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MerchantCashAdvance;
