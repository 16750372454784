import "./App.css";
import React, { useState } from "react";

import BusinessLoans from "./Component/BusinessLoans";
import BusinessFundingSolution from "./Component/BusinessFundingSolution";

import MerchantService from "./Component/MerchantService";
import About from "./Component/About";
import Home from "./Component/Home";
import Career from "./Component/Career";
import ContactUs from "./Component/ContactUs";
import Services from "./Component/Services";
import WhatWeOffer from "./Component/WhatWeOffer";
import Utilities from "./Component/Utilities";
import Contactform from "./Component/Contactform";
import MerchantCashAdvance from "./Component/MerchantCashAdvance";
import Telecommunications from "./Component/Telecommunications";
import {
  HashRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import "wow.js/css/libs/animate.css"; // Import wow.js CSS
import WOW from "wow.js"; // Import wow.js library

import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

function App() {
  const wow = new WOW();
  wow.init();

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [isSidebarOpen1, setIsSidebarOpen1] = useState(false);

  const toggleSidebar1 = () => {
    setIsSidebarOpen1(!isSidebarOpen1);
  };
  const [isSidebarOpen2, setIsSidebarOpen2] = useState(false);

  const toggleSidebar2 = () => {
    setIsSidebarOpen2(!isSidebarOpen2);
  };

  return (
    <>
      <Router>
        {/* <LoadingBar/> */}
        <div className=" text-gray-800 font-sans">
          <div className=" justify-items-center lg2:px-32 lg3:px-72 bg-blue-950 bg-blue-1000 text-white hidden lg:grid  grid-cols-3 ">
            <div className=" my-auto">
              <p>+44 3333 444 941</p>
            </div>
            <div className=" my-auto">
              <p>Havelock Hub 14 Havelock Place Harrow HA1 1L J</p>
            </div>
            <div>
              <div className="grid  ml-5  gap-10  w-28 py-3  grid-cols-4">
                <a
                  target="_blank"
                  href="https://www.facebook.com/AMSgroupltdUK/"
                  role="button"
                >
                  {/* <!-- Facebook --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </a>

                <a
                  target="_blank"
                  href="https://www.instagram.com/p/CRrfOOaLUHn/"
                  role="button"
                >
                  {/* <!-- Instagram --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a
                  target="_blank"
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2FTheAMSLtdGroup1"
                  role="button"
                >
                  {/* <!-- Twitter --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/shirish-patel-57a88134/?originalSubdomain=uk"
                  role="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className=" lg:py-4  bg-local  ">
            <div className="container mx-auto lg:px-4">
              <nav className="flex items-center justify-between">
                {/* <!-- Brand/logo --> */}
                <img
                  className=" lg:flex   w-36 "
                  src="../img/logo.png"
                  alt=""
                />

                <div className="w-full  lg:hidden ">
                  <div className="flex  py-10 ">
                    <div className="  mx-auto w-32"></div>
                    {/* <img className=" w-32 mx-auto " src="../img/logo.png" alt="" /> */}
                    <div
                      onClick={handleNav}
                      className=" px-3 py-3 mx-5  bg-amber-400  text-white lg:hidden"
                    >
                      {nav ? (
                        <AiOutlineClose size={20} className=" " />
                      ) : (
                        <AiOutlineMenu size={20} className=" " />
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      nav
                        ? " bg-slate-50 z-50 px-5 fixed top-0 left-0 w-[90%] h-full text-lg  ease-in-out duration-500"
                        : "ease-in-out duration-500 fixed left-[-100%]"
                    }
                  >
                    <ul className="divide-y-2   divide-gray-200">
                      <div
                        onClick={handleNav}
                        className=" px-2 py-5  flex  justify-end lg:hidden"
                      >
                        {nav ? (
                          <AiOutlineClose size={20} className=" " />
                        ) : (
                          <AiOutlineMenu size={0} className=" " />
                        )}
                      </div>

                      <li className=" py-5">
                        <Link
                          to="/"
                          className="hover:text-amber-400   leading-2 cursor-pointer transform transition-transform hover:scale-110"
                        >
                          Home
                        </Link>
                      </li>
                      <li className=" py-5">
                        <Link
                          to="/about"
                          className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                        >
                          About
                        </Link>
                      </li>

                      <li class="  py-5">
                        <NavLink
                          to="/services"
                          className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                        >
                          Services{" "}
                        </NavLink>

                        <button
                          className=" hover:text-amber-400 transition"
                          onClick={toggleSidebar}
                        >
                          &nbsp; <space className=" text-xs">▼</space>
                        </button>
                        <div
                          className={` left-0  p-2  ${
                            isSidebarOpen ? "block" : "hidden"
                          }`}
                        >
                          <ul className="block w-full    ">
                            <li className="py-2">
                              <Link
                                to="/businessFundingSolution"
                                className="hover:text-amber-400 leading-2   transition"
                              >
                                &gt;Business Funding Solution&nbsp;{" "}
                              </Link>
                              <button
                                className=" hover:text-amber-400 transition"
                                onClick={toggleSidebar2}
                              >
                                &nbsp; <space className=" text-xs">▼</space>
                              </button>
                              <div
                                className={` left-0  p-2  ${
                                  isSidebarOpen2 ? "block" : "hidden"
                                }`}
                              >
                                <ul className="block w-full    ">
                                  <li className="py-2">
                                    <Link
                                      to="/businessLoans"
                                      className="hover:text-amber-400    leading-2 cursor-pointer transform transition-transform hover:scale-110"
                                    >
                                      &gt;Business Loans
                                    </Link>
                                  </li>
                                  <li className="py-2">
                                    <Link
                                      to="/merchantCashAdvance"
                                      className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                                    >
                                      &gt;Merchant Cash Advance
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </li>

                            <li className="py-2">
                              <Link
                                to="/merchantService"
                                className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                              >
                                &gt;Merchant Service
                              </Link>
                            </li>
                            <li className="py-2">
                              <Link
                                to="/utilities"
                                className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                              >
                                &gt;Utilities
                              </Link>
                            </li>
                            <li className="py-2">
                              <Link
                                to="/telecommunications"
                                className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                              >
                                &gt;Telecommunications
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="py-5">
                        <Link
                          to="/career"
                          className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                        >
                          Career{" "}
                        </Link>
                        <button
                          className=" hover:hover:text-amber-400 transition "
                          onClick={toggleSidebar1} // Call toggleSidebar on click
                        >
                          &nbsp; <space className=" text-xs">▼</space>
                        </button>
                        <div
                          className={` left-0  p-2  ${
                            isSidebarOpen1 ? "block" : "hidden"
                          }`}
                        >
                          <ul class="block w-full   ">
                            <li>
                              <Link
                                to="/whatWeOffer"
                                className="hover:text-amber-400 leading-2  cursor-pointer transform transition-transform hover:scale-110"
                              >
                                &gt;What We Offer
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className=" py-5">
                        <Link
                          to="/contactUs"
                          className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                    <li className=" w-36 py-5 gap-14  grid-cols-4 items-center  grid">
                      {/* <!-- Facebook --> */}
                      <a
                        target="_blank"
                        href="https://www.facebook.com/AMSgroupltdUK/"
                        role="button"
                      >
                        {/* <!-- Facebook --> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                          fill="currentColor"
                          style={{ color: "#000000" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                        </svg>
                      </a>

                      <a
                        target="_blank"
                        href="https://www.instagram.com/p/CRrfOOaLUHn/"
                        role="button"
                      >
                        {/* <!-- Instagram --> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                          fill="currentColor"
                          style={{ color: "#000000" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                        </svg>
                      </a>

                      <a
                        target="_blank"
                        href="https://twitter.com/i/flow/login?redirect_after_login=%2FTheAMSLtdGroup1"
                        role="button"
                      >
                        {/* <!-- Twitter --> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                          fill="currentColor"
                          style={{ color: "#000000" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                        </svg>
                      </a>
                      {/* <!-- Linkedin --> */}
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/shirish-patel-57a88134/?originalSubdomain=uk"
                        role="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class=" leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                          fill="currentColor"
                          style={{ color: "#000000" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                        </svg>
                      </a>
                    </li>
                    <NavLink to="/Contactform">
                      <button className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">
                        APPLY NOW
                      </button>
                    </NavLink>
                  </div>
                </div>
                <ul className="hidden mobile-menu z-50   lg:flex space-x-4">
                  <li>
                    <Link
                      to="/ "
                      className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="hover:text-amber-400   leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      About
                    </Link>
                  </li>
                  <li class="dropdown inline   cursor-pointer  text-base  tracking-wide">
                    <NavLink
                      to="/services"
                      className="hover:text-amber-400  leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Services &nbsp; <space className=" text-xs">▼</space>
                    </NavLink>

                    <div class="dropdown-menu absolute hidden h-auto  pt-4">
                      <ul class="block w-full bg-white shadow px-5 py-2">
                        <li class="">
                          <div className="relative group">
                            <Link
                              to="/businessFundingSolution"
                              className="hover:text-amber-400 leading-2   transition"
                            >
                              &gt;Business Funding Solution&nbsp;{" "}
                              <space className=" ">&gt;</space>
                            </Link>

                            <div className="absolute -right-60 -top-4 w-60  bg-white p-2  hidden group-hover:block">
                              <ul className="mt-2 space-y-2">
                                <li>
                                  <Link
                                    to="/businessLoans"
                                    className="hover:text-amber-400 leading-2  cursor-pointer transform transition-transform hover:scale-110"
                                  >
                                    &gt;Business Loans
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/merchantCashAdvance"
                                    className="hover:text-amber-400 leading-2  cursor-pointer transform transition-transform hover:scale-110"
                                  >
                                    &gt;Merchant Cash Advance
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/merchantService"
                            className="hover:text-amber-400 leading-2  cursor-pointer transform transition-transform hover:scale-110"
                          >
                            &gt;Merchant Service
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/utilities"
                            className="hover:text-amber-400 leading-2  cursor-pointer transform transition-transform hover:scale-110"
                          >
                            &gt;Utilities
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/telecommunications"
                            className="hover:text-amber-400 leading-2  cursor-pointer transform transition-transform hover:scale-110"
                          >
                            &gt;Telecommunications
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="dropdown inline   cursor-pointer  text-base  tracking-wide">
                    <Link
                      to="/career"
                      className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Career &nbsp; <space className=" text-xs">▼</space>
                    </Link>

                    <div class="dropdown-menu absolute hidden h-auto  pt-4">
                      <ul class="block w-full bg-white shadow px-2 py-2">
                        <li className="py-2">
                          <Link
                            to="/whatWeOffer"
                            className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                          >
                            &gt;What We Offer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link
                      to="/contactUs"
                      className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>

                <div className="hidden mobile-menu lg:flex space-x-4">
                  <NavLink to="/Contactform">
                    <button className="bg-amber-400 text-white px-8 py-3 rounded-md ">
                      APPLY NOW
                    </button>
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
          <hr />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/merchantService" element={<MerchantService />} />
            <Route
              path="/telecommunications"
              element={<Telecommunications />}
            />
            <Route path="/businessLoans" element={<BusinessLoans />} />
            <Route
              path="/merchantCashAdvance"
              element={<MerchantCashAdvance />}
            />
            <Route path="/utilities" element={<Utilities />} />
            <Route path="/whatWeOffer" element={<WhatWeOffer />} />
            <Route path="/contactform" element={<Contactform />} />
            <Route
              path="/businessFundingSolution"
              element={<BusinessFundingSolution />}
            />
          </Routes>
          <footer class="">
            <div class="md:grid  grid-cols-4 bg-blue-950 bg-blue-1000 py-4 px-5 lg:py-8 lg:px-32 lg2:pr-36 lg3:pr-56 ">
              <div class="col-span-1   lg:text-left">
                <p class="text-yellow-400 font-bold text-2xl py-5">About</p>
                <p class="text-lg text-white">
                  AMS Group offers affordable utilities, energy,
                  telecommunications, and insurance for businesses.
                </p>
                <div className=" w-36 py-5 gap-8  grid-cols-4 items-center  grid">
                  {/* <!-- Facebook --> */}
                  <a
                    target="_blank"
                    href="https://www.facebook.com/AMSgroupltdUK/"
                    role="button"
                  >
                    {/* <!-- Facebook --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                      fill="currentColor"
                      style={{ color: "#ffffff" }}
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </a>

                  <a
                    target="_blank"
                    href="https://www.instagram.com/p/CRrfOOaLUHn/"
                    role="button"
                  >
                    {/* <!-- Instagram --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                      fill="currentColor"
                      style={{ color: "#ffffff" }}
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </a>

                  <a
                    target="_blank"
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2FTheAMSLtdGroup1"
                    role="button"
                  >
                    {/* <!-- Twitter --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                      fill="currentColor"
                      style={{ color: "#ffffff" }}
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/shirish-patel-57a88134/?originalSubdomain=uk"
                    role="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="leading-2 cursor-pointer transform transition-transform hover:scale-110 h-8 w-8"
                      fill="currentColor"
                      style={{ color: "#ffffff" }}
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-span-1 lg:px-10  lg:text-left">
                <p class="text-yellow-400 font-bold text-2xl py-5">
                  Quick Links
                </p>
                <ul class="text-lg text-white ">
                  <li>
                    <a href="">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="">Terms Of Service</a>
                  </li>
                  <li>
                    <a href="">Credits</a>
                  </li>
                  <li>
                    <a href="">FAQ</a>
                  </li>
                </ul>
              </div>
              <div class="col-span-1  lg:text-left">
                <p class="text-yellow-400 font-bold text-2xl py-5">
                  Our Service
                </p>
                <ul class="text-lg text-white">
                  <li>
                    <Link
                      to="/merchantService"
                      className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Merchant Service
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/utilities"
                      className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Utilities
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/telecommunications"
                      className="hover:text-amber-400 leading-2 cursor-pointer transform transition-transform hover:scale-110"
                    >
                      Telecommunications
                    </Link>
                  </li>

                  <li>
                    {" "}
                    <Link
                      to="/businessFundingSolution"
                      className="hover:text-amber-400 leading-2   transition"
                    >
                      Business Funding Solution&nbsp;{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="col-span-1  lg:text-left">
                <p class="text-yellow-400 font-bold text-2xl py-5">
                  Get in touch
                </p>
                <p class="text-lg text-white pb-5">Call Us: +44 3333 444 941</p>
                <p class="text-lg text-white">
                  Havelock Hub <br />
                  14 Havelock Place <br />
                  Harrow <br />
                  HA1 1LJ
                </p>
              </div>
            </div>
            <div className="lg:px-32 px-5 bg-blue-1000  lg2:pr-36 lg3:pr-56">
              <div className="  text-center pt-5 text-sm text-white">
                <p>
                  We are a credit broker, not a lender. All Merchant Solutions
                  Limited can introduce applicants to a number of finance
                  providers based on the applicants’ circumstances and
                  creditworthiness. All Merchant Solutions Limited does not
                  charge customers a fee for using its broker service, but
                  receives a commission from lenders for effecting such
                  introductions. For certain lenders we may have influence over
                  the interest rate, and this may impact the total amount
                  payable by you, the customer. Applicants must be aged 18 and
                  over, Terms and conditions apply. All finance and quotes are
                  subject to status and income. Guarantees and Indemnities may
                  be required.
                </p>
                <p>
                  We do not offer ‘short-term loans’, a Merchant Cash Advance
                  (also known as a Business Cash Advance) is a business finance
                  product and is not a consumer loan. The minimum duration is 4
                  months, but the average term can range between 6 to 12 months.
                </p>
              </div>
            </div>

            <div className=" lg:grid bg-blue-950 bg-blue-1000 text-white grid-cols-2  py-5 grid-rows-1">
              <div className="lg:ml-72 text-center ">
                <p>Copyright © 2023 AMS Group</p>
              </div>
              <div className="lg:mr-72 text-center">
                <a href="https://jkinfosysglobal.com/" target="_blank">
                  <p>Powered By JK Infosys Global</p>
                </a>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </>
  );
}

export default App;
