import React from 'react'

const WhatWeOffer = () => {
  return (
    <div>
      <div class="bg-gray-300 text-center bg-cover w-full bg-center    bg-no-repeat" style={{ backgroundImage: `url(${'../img/istockphoto-1214048300.jpg'})` }}>
        <h1 class=" py-20 md:py-72 text-3xl lg:text-6xl backdrop-contrast-75 text-white font-bold">What We Offer</h1>
    </div>

    <div class="px-5 py-5   lg2:px-32 lg3:px-72">
        <h4 class="py-4 text-2xl text-blue-900 font-bold">Our Training Academy</h4>
        <p class="text-lg">Our in-house training is your Sales 101, the A to Z of smart persuasion. You get the tools you need
            to find customers, close deals, max your commission and build your business. You will also have time to fire
            questions at your Manager and Coach and hobnob with another sales folk.</p>

        <h4 class="py-4 text-2xl text-blue-900 font-bold">Quick Invoicing</h4>
        <p class="text-lg">You want to get paid on time. So, we make sure your invoice is processed faster. You can quote us on
            that. Our commission is the most generous in the industry, so you will be flush in a flash. After all,
            getting people paid is what we do.</p>

        <h4 class="py-4 text-2xl text-blue-900 font-bold">Ongoing Training</h4>
        <p class="text-lg">Get the help you need to bring your sales A-game. We give you face-to-face and phone coaching from
            other super star sellers. Dip into webinars, e-learning programs and access a suite of digital learning
            materials anytime.</p>

        <h4 class="py-4 text-2xl text-blue-900 font-bold">Qualified Appointments</h4>
        <p class="text-lg">Prove yourself and our team of tele-appointers will be your allies at base camp. They work hard to
            get you a foot in the door, finding businesses who would appreciate a visit. They ’ll qualify the
            appointment and put the time and date in your calendar (dependent on performance). All you do is turn up and
            turn on the charm..</p>

        <h4 class="py-4 text-2xl text-blue-900 font-bold">Industry-leading Tech </h4>
        <p class="text-lg">Some companies leave their salespeople to it. We arm you with digital tools. They are a doddle to
            use - on tablet, phone, or laptop. You can manage deals and report on how your sales are adding up.
            Everything is paperless and updates in real time.</p>

        <h4 class="py-4 text-2xl text-blue-900 font-bold">Making a difference </h4>
        <p class="text-lg">Imagine selling a product that really makes a tangible difference to small business owners? We have
            got something that saves businesses money, time, and hassle, while making sure they get paid to do what they
            love. It is hardly a hard sell.</p>
    </div>
    </div>
  )
}

export default WhatWeOffer
