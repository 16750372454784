import React from 'react'
import {  NavLink } from 'react-router-dom';


const Telecommunications = () => {
  return (
    <div>
      <div class=" text-center bg-cover  bg-center w-full bg-gray-500    bg-no-repeat" style={{ backgroundImage: `url(${'../img/telework-685585.png'})` }}>
        <h1 class=" py-40 md:py-72 text-3xl backdrop-contrast-25 lg:text-6xl text-white font-bold">Telecommunications</h1>
    </div>
    <div class="lg2:px-32 lg3:px-72 px-5 text-blue-900 font-bold text-3xl py-5">
        <p>AMS is the partner with one of the biggest Telecommunication suppliers</p>
    </div>
    <div class="md:grid md:grid-cols-2 lg:grid-cols-3 px-2 lg2:px-32 lg3:px-72">
        <div class="px-5  hover:shadow-lg hover:shadow-blue-500 py-5">
            <div class="py-2"><img class="w-20" src="../img/arrows.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Easy switching</p>
            <p class="text-lg">Switching your business broadband could not be easier – in fact we’ll handle everything.
            </p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/tag.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Price match guarantee</p>
            <p class="text-lg">We are proud of our low prices and guarantee to beat any offer that you receive.</p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/router.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Free wireless router</p>
            <p class="text-lg">All our routers are ready to run straight out of the box. Simply plug it in to get your
                business online.</p>
        </div>

        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/stars.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">A better alternative</p>
            <p class="text-lg">As one of the UK’s fastest-growing providers, we are dedicated to making sure small
                businesses have the same advantages as larger corporations. Our no-frills prices and 5-star service is
                shaking up the telecommunications market.</p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/www.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Affordable internet solutions
            </p>
            <p class="text-lg">Our fair and honest pricing is designed to keep your costs low. We guarantee to beat any
                offer.</p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/walet.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Affordable?– Yes!</p>
            <p class="text-lg"></p>
        </div>

        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/medal.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Excellent Service?– Yes!</p>
            <p class="text-lg">We’re committed to showing that having fair prices doesn’t come at the cost of great
                service. Small businesses don’t have to settle for less.
            </p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/chain.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Switching is easy</p>
            <p class="text-lg">Our dedicated onboarding teams make the switching to easy – we’ll handle everything
                leaving you to focus on growing your business.</p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/plant.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Benefit from one supplier</p>
            <p class="text-lg">The all-in-one small business solution powered by products to help run and grow your
                business for less. Join the community of 31,000 UK small businesses who have benefitted from one
                supplier. We will support you every step of the way.</p>
        </div>

        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/piggyBank.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Save costs</p>
            <p class="text-lg">Businesses who bundle two or more products and save on average £600 yearly.
            </p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/miniClock.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Save time</p>
            <p class="text-lg">Focus on the work you love, not sourcing and managing multiple suppliers.</p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5">
            <div class="py-2"><img class="w-20" src="../img/tagOnHand.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Save hassle</p>
            <p class="text-lg">Because everything is in one place, you’ll never need to worry who to call for support.</p>
        </div>

        <div class="px-5 hover:shadow-lg hover:shadow-blue-500  py-5 col-span-2">
            <div class="py-2"><img class="w-20" src="../img/yoga.jpg" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Get peace of mind</p>
            <p class="text-lg">Achieve key account status for all your products with one supplier.</p>
        </div>

        <div class="col-span-1">
            <div class="text-center mt-32">
            <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                        QUOTE</button>
                    </NavLink>
            </div>
        </div>
    </div>

    <div class="px-5 lg2:px-32 lg3:px-72 text-blue-900 font-bold text-3xl py-5">
        <p>Our Products</p>
    </div>
    <div class="md:grid grid-cols-2 px-2 lg2:px-32 lg3:px-72">
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500 py-5">
            <div class="py-2"><img class="w-20" src="../img/router2.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Unlimited business broadband</p>
            <p class="text-lg">Accelerate your business with fast, reliable internet access.
                As small business specialists, we provide three powerful and affordable connectivity solutions to equip your business in the digital age.
            </p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500 py-5">
            <div class="py-2"><img class="w-20" src="../img/network.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Unlimited business fiber</p>
            <p class="text-lg">Accelerate your business with fast, reliable internet access.
                As small business specialists, we provide three powerful and affordable connectivity solutions to equip your business in the digital age.</p>
        </div>

        <div class="px-5 hover:shadow-lg hover:shadow-blue-500 py-5">
            <div class="py-2"><img class="w-20" src="../img/phone.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">VoIP</p>
            <p class="textxlg">
                <h4 class="text-blue-900 font-bold">The VoIP phone system designed for business owners!</h4>
                Our VoIP phone system is flexible, cost-effective, feature-rich, and simple to use. We have designed it for <br/>
                
                UK business owners and small teams. <br/>
                
                <b>Everything you need to run your business</b> <br/>
                
                Keep your same business phone number and access better quality calls, a wide range of business-grade smart features and all-inclusive UK landline & mobile calls.
            </p>
        </div>
        <div class="px-5 hover:shadow-lg hover:shadow-blue-500 py-5">
            <div class="py-2"><img class="w-20" src="../img/smallPC.png" alt=""/></div>
            <p class="text-blue-900 font-bold text-xl underline underline-offset-2 py-2">Websites</p>
            <p class="text-xl">
                <h4 class="text-blue-900 font-bold">Here is the peace-of-mind you need</h4>
                You get… <br/>
                Your own dedicated certified <br/>
                website designer <br/>
                100% mobile and SEO friendly <br/>
                Ongoing training and support <br/>
                Price match guarantee <br/>
                No contract or hidden fees <br/>
            </p>
        </div>
    </div>
    <div class="py-5">
        <div class="text-center mt-32">
        <NavLink to="/Contactform">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">GET A FREE
                        QUOTE</button>
                    </NavLink>
        </div>
    </div>
    </div>
  )
}

export default Telecommunications
 