import React, { useState } from 'react';
import emailjs from 'emailjs-com';



const Services = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    // const handleSubmit = (event) => {
    //   event.preventDefault();



    const sendEmail = (e) => {
        e.preventDefault();
        if (!isSubmitted) {
            emailjs.sendForm('service_lcce4k5', 'template_4ovkkjt', e.target, '5IZopW2pUsvTML9sY')
                .then((result) => {
                    if (!alert("Data Stored")) document.location = "/"
                    alert('Email sent successfully:', result.text);
                })
                .catch((error) => {
                    alert("plz fill the data");
                    console.error('Email error:', error);
                });
        };
        setIsSubmitted(true);
    }
    // };

    return (
        <div >
            <div class=" text-center  bg-cover w-full bg-center    bg-no-repeat" style={{ backgroundImage: `url(${'../img/types-of-itil-services.jpg'})` }}>
                <h1 class=" py-20 md:py-72 text-3xl lg:text-6xl backdrop-contrast-75 text-white font-bold">Services</h1>
            </div>
            <div class=" bg-gray-100 px-10 lg2:px-32 lg3:px-72 py-5">
                <ul class="list-disc text-lg leading-8">
                    <li>We work to get you the best price on your bills for your utility services like, Merchant Services,
                        Energy, Telecommunications and look after your insurance as well. Saving you time and money to spend on
                        the things that really matter.</li>
                    <li>So many different devices and energy being used around the clock, it is vital you find a deal that helps
                        cut costs. we have built strong partnerships with trusted business utility suppliers, giving you instant
                        access to incredible utility deals.</li>
                    <li>Many of customers enjoy big savings on their business energy bills by helping them switch to cheaper
                        tariffs.</li>
                    <li>So, whether you run a start-up enterprise, a small to medium-sized business or a large multinational
                        corporation, we can help you make sure you are on the right plan for your energy supply. And you can
                        start saving money on your business utility bills by switching to a cheaper deal today with AMS Group.
                    </li>
                </ul>
            </div>
            <div class="px-10 lg2:px-32 lg3:px-72">
                <h4 class="text-yellow-400 font-bold py-1 text-lg">Our Services</h4>
                <p class="text-blue-900 font-bold text-4xl py-2">Trust the Experience</p>
            </div>
            <div class="bg-gray-50  ">
                <div class="grid md:grid-cols-2  lg:grid-cols-4 bg-gray-100 lg2:px-32 lg3:px-72 px-10 py-5">

                    <div class="col-span-1 hover:bg-amber-300 border"><img class="px-5 py-2 w-full" src="../img/comm1.1.png" alt="" />

                        <h4 class=" font-bold px-10 text-xl">Utilities</h4>
                        <p class="text-lg  py-5 px-10">AMS is a UK-based price comparison expert for commercial gas,
                            electric, and water, offering quick, simple solutions.</p>


                    </div>
                    <div class="col-span-1 hover:bg-amber-300 border"><img class="px-5 py-2 w-full" src="../img/comm2.1.png" alt="" />

                        <h4 class="px-10 font-bold text-xl ">Merchant Services</h4>
                        <p class="text-lg  py-5 px-10">Merchant Solutions offers PCI DSS-approved, SSL-certified
                            ecommerce payment terminals.</p>


                    </div>
                    <div class="col-span-1 border hover:bg-amber-300"><img class="px-5 py-2 w-full" src="../img/conm3.1.jpg" alt="" />


                        <h4 class="px-10 font-bold  text-xl ">Telecommunications</h4>
                        <p class="text-lg  py-5 px-10">AMS is the partner with one of the biggest Telecommunication
                            suppliers, bOnline.</p>


                    </div>
                    <div class="col-span-1 hover:bg-amber-300 border"><img class="px-5 py-2 w-full" src="../img/comm4.1.jpg" alt="" />


                        <h4 class="px-10 font-bold text-xl ">Business funding solution</h4>
                        <p class="text-lg  py-5 px-10">AMS is providing best funding solution for your business</p>


                    </div>

                </div>
            </div>
            <div class="lg2:px-32 lg3:px-72">
                <h4 class="text-yellow-400 font-bold py-1 text-lg text-center">Roof Inspection</h4>
                <p class="text-blue-900 font-bold text-2xl lg:text-4xl py-1 text-center">Scheduling An Appointment</p>
            </div>
            <div class="px-20 lg2:px-32   lg3:px-72 py-10">
            <form action="" className=' cols-span-3 flex  items-center ' onSubmit={sendEmail} >
                    <div class=" md:flex
                     my-2   mx-auto ">
                        <div className='col-span-1 py-2  lg:mx-2 '>
                            <input type="text" required name='user_name' placeholder="Enter your full name" class="border-2 px-2 text-lg bg-gray-100" />

                        </div>
                        <div className='col-span-1 py-2 md:mx-2 
                        '>
                            <input required name='user_phone_number' class="border-2  px-2   text-lg bg-gray-100" type="number" placeholder="Phone number" />

                        </div>
                        <div className=' py-2 md:mx-2'>
                            <a href="" target="_blank">
                                <button
                                    className="bg-amber-400 py-2   text-white px-12  text-sm rounded-md ">SEND</button>
                            </a>
                        </div>
                      

                    </div>
                    

                </form>
            </div>


        </div>
    )
}

export default Services
