import React from 'react'
import {  NavLink } from 'react-router-dom';


const About = () => {
    return (
        <div>   
            <div  class="w-full bg-contain   md:bg-center   md:bg-cover bg-left-top contrast-75 px-10   bg-no-repeat h-auto lg:px-20 pt-80 lg:py-96  bg-gray-300" style={{ backgroundImage: `url(${'../img/pos-payments.jpg'})` }}>
                <h1 class=" text-blue-950 pt-5  font-bold text-3xl lg:text-6xl">About Us</h1>
                <p class="text-blue-950 text-lg  lg:text-xl py-3">Learn more about us, our mission and the history behind Merchant Loan
                    Advance.</p>
            </div>

            <div class="md:grid grid-cols-2 lg:mt-32 mt-16 ">
                <div class="col-span-1 lg:mx-20 hover:shadow-2xl hover:shadow-amber-400 lg:px-20 px-5 ">
                    <h5 class="lg:text-xl text-lg text-amber-400 font-bold ">About Company</h5>
                    <div class="lg:text-4xl text-3xl text-blue-900 font-bold py-5">UK’s most trusted Merchant service provider</div>
                    <div class="pb-20 lg:text-xl text-lg">Helping UK businesses find better deals on their business utility bills To us the
                        most
                        important part of
                        our business is you, our customer. That is why our energy is simple, personal, and sustainable.
                    </div>
                </div>
                <div class=" col-span-1 hover:shadow-2xl hover:shadow-amber-400 lg:py-5 lg:mx-10 px-5">
                    <div class="lg:text-xl text-lg">Do not want to spend hours looking for the right energy tariff? Give us a few details
                        and let us find one for you.</div>
                    <img class="lg:pt-20 pt-14" src="../img/handshake.png" alt="" />
                    <h5 class="lg:text-xl text-lg text-black-500 font-bold py-5">Need help? Contact me</h5>

                    <div class="flex flex-row">
                        <img class="w-8 mr-5" src="../img/mail.png" alt="" />
                        <div>info@amsltdgroup.com</div>
                    </div>
                    <div class="flex flex-row pt-5">
                        <img class="w-8 mr-5" src="../img/call.png" alt="" /><br />
                        <div>+44 3333 444 941</div>
                    </div>
                </div>
            </div>

            <div class="text-center mt-32">
            <NavLink target='_blank' to="https://amsltdgroup.swoopfunding.com/login">
                      <button
                        className="bg-amber-400 py-2 my-5 text-white px-5  text-sm rounded-md ">APPLY
                        NOW</button>
                    </NavLink>            </div>

            <div class=" mt-8 lg:px-32 lg:py-20 py-10 bg-amber-400">
                <div class=" bg-amber-400 mx-10 w-auto hover:shadow-2xl shadow-black-500  ">
                    <div class="text-center font-bold text-blue-900 py-5">Our Service</div>
                    <div class="text-center font-bold text-white lg:text-4xl text-3xl">Superior Services</div>
                    <div class="lg:grid grid-cols-5 grid-flow-col grid-row-1  py-10">
                        <div class="w-full ">
                            <img class=" w-full" src="../img/demo1.png" alt="" />
                        </div>
                        <div class="w-full ">
                            <img class="" src="../img/demo2.png" alt="" />
                        </div>
                        <div class="col-span-3  text-white text-xl">
                            <div class="text-white text-xl  px-5 py-5"> Our services</div>
                            <div class="text-white textlg  px-5 ">
                                AMS Group offers affordable utility services for merchant services, energy, telecommunications,
                                and
                                insurance, ensuring you get the best price on your bills. With partnerships with trusted
                                suppliers,
                                we provide instant access to incredible deals, allowing customers to save money on their
                                business
                                energy bills. Whether you're a start-up, small-medium-sized, or multinational corporation, AMS
                                Group
                                can help you find the right plan for your energy supply.

                                <div class="  mt-10 lg:mt-20">
                                <NavLink target='_blank' to="/Services">
                      <button
                        className="text-amber-400 py-2 my-5 hov  bg-white px-5  text-sm rounded-md ">READ
                        MORE</button>
                    </NavLink>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </div>

            <div class="lg:mx-72 lg:my-20 lg:px-20 mx-5 my-5 px-5 py-10 hover:shadow-2xl hover:shadow-amber-400  ">
                <h5 class="text-lg lg:text-xl text-amber-400 font-bold ">Why Choose Us?</h5>
                <div class="text-3xl lg:text-4xl text-blue-900 font-bold py-5">Reason For Choosing Us</div>
                <div class="text-lg">AMS currently provides more than 400 small businesses with outstanding value for
                    money on Merchant Services, business energy services, Telecoms and Insurance supported by a dedicated team
                    of UK customer service advisors. Our small business owners across the UK rely on us to save them money and
                    to help them serve their customers 24/7.
                </div>
            </div>

        </div>
    )
}

export default About
