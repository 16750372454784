import React from 'react'

const Career = () => {
  return (
    <div class=" ">
       <div class=" text-center   bg-center     " style={{ backgroundImage: `url(${'../img/istockphoto-1333992102.jpg'})` }}>
        <h1 class=" py-20  md:py-72 backdrop-contrast-50	
         text-3xl lg:text-6xl text-white font-bold">Career</h1>
    </div>

    <div>
        <h1 class="text-3xl lg:text-4xl text-blue-900 font-bold text-center py-10 ">Self-employed careers</h1>
    </div>
    <div class="md:grid grid-cols-2 gap-4 px-5 lg2:px-32 lg3:px-72">
        <div class="col-span-1 lg:px-10">
            <h4 class="text-2xl py-2 text-blue-900 font-bold underline underline-offset-4">Flexible working. Enviable
                earning.</h4>
            <p class="text-lg leading-8 py-2">Talk about right place, right time. We are in a golden age of card
                payments; small businesses are booming
                and dissatisfaction with banks is at an all-time high. And here we are with a product that capitalises
                on all the above.</p>
            <p class="text-lg leading-8 ">At AMS, we are all about making card payments fast, easy, and affordable for
                small and medium businesses.
                Which puts us in a particularly good place. We need great salespeople like you to spread our message. It
                could be the easiest sell you ever make.</p>

            

        </div>
        <div class="col-span-1 lg:px-10">
            <h4 class="text-2xl py-2 text-blue-900 font-bold underline underline-offset-4">Be your own boss</h4>
            <p class="text-lg leading-8 py-2">Here is a thought: what if you did not have to choose between flexible
                hours and decent earnings? Our sales agents manage their own work life balance and earn commissions like
                a boss. There are plenty of reasons to go self-employed. You get to ‘be your own boss’ and work more
                flexibly, you may be able to command a much higher rate than the salary you would be able to achieve as
                an employee</p>

         
        </div>
       
    </div>
    <div class="md:grid gap-4 py-5 grid-cols-2 px-5 lg2:px-32 lg3:px-72">
        <div class="col-span-1 lg:px-10">
            <h4 class="text-2xl pt-5 text-blue-900 font-bold underline underline-offset-4">Uncapped Earnings</h4>
            <p class="text-lg leading-8 py-2">We are in the business of getting people paid and we will make it rain for
                you too.</p>
        </div>
        <div class="col-span-1 lg:px-10">
            <h4 class="text-2xl pt-5 text-blue-900 font-bold underline underline-offset-4">Get the best training and
                support</h4>
            <p class="text-lg leading-8 py-2">Our training is recognised as the industry pinnacle. We will not just demo and dash: you will get the
                best support when you need it, as long you need it. We are only call away.</p>
        </div>
    </div>
    </div>
  )
}

export default Career
